import { ChangeDetectionStrategy, Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core'; import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CoreService } from '../core.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import DownloadHelper from '../download-helper';
import { StudentParameterService } from './student-parameter.service';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {

  userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
  advancedSearchForm: FormGroup;
  delete_id = -1;
  onDeleteType = false;
  onResetType = false;
  reset_id = -1;
  tableRecords = [];
  tableParams = new HttpParams();
  // tableTotalRecords = 0;
  // tableCurPage = 1;
  // tablePageSize = 20;
  sortby = '';
  DESC = false;
  studentData: [];
  schools = [];
  searchName = '';
  // searchTerm = '';
  export = false;
  school_filter_check = true;


  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');
  school_id = localStorage.getItem('curSchool');

  @ViewChild('modal_content', { static: false })
  private modelContent!: TemplateRef<any>;
  modal_body: string = '';
  modal_title: string = '';
  modalDetails: any = {
    modal_title: '',
    modal_body: '',
    modal_type: ''
  };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private coreService: CoreService,
    private sanitizer: DomSanitizer,
    private studentParameterService: StudentParameterService
  ) { }

  ngOnInit() {

    this.advancedSearchForm = this.formBuilder.group({
      school: [this.school],
      imageCheck: [this.imageCheck],
      searchTerm: ['']
    });
    this.getSchools();
    this.resetAndRefreshTable();
    this.checkRole();
  }

  get school(): string {
    return this.studentParameterService.school;
  }

  set school(value: string) {
    this.studentParameterService.school = value;
  }

  get imageCheck(): string {
    return this.studentParameterService.imageCheck;
  }

  set imageCheck(value: string) {
    this.studentParameterService.imageCheck = value;
  }

  get searchTerm(): string {
    return this.studentParameterService.searchTerm;
  }

  set searchTerm(value: string) {
    this.studentParameterService.searchTerm = value;
  }

  get tableCurPage(): number {
    return this.studentParameterService.page;
  }

  set tableCurPage(value: number) {
    this.studentParameterService.page = value;
  }

  get tablePageSize(): number {
    return this.studentParameterService.perPage;
  }

  set tablePageSize(value: number) {
    this.studentParameterService.perPage = value;
  }

  get tableTotalRecords(): number {
    return this.studentParameterService.totalRecords;
  }

  set tableTotalRecords(value: number) {
    this.studentParameterService.totalRecords = value;
  }

  checkRole(){
    if (this.subscriber_id) {
      let schoolsArr = this.school_id.split(",");
      if (schoolsArr.length == 1) {
        this.school_filter_check = false;
      }
    }
  }

  getSchools() {
    let url = 'api/school';

    if (this.subscriber_id) {
      url += '?subscriber_id=' + this.subscriber_id;
      // if (this.school_id) {
      //   url += '&school_id='+this.school_id;
      // }
    }

    this.coreService.getRequest(url)
      .then((data) => {
        if (data && data.success) {
          // this.departments = data.data.departments.data;
          let schooldata = [];
          this.schools = data.data.schools.data.filter(s => s.school_code !== 'None');
          if (this.school_id) {
            let schoolsArr = this.school_id.split(",");
            this.schools = this.schools.filter(s =>
              schoolsArr.includes((s.id).toString())
            );
          }

          // for (var i = 0; i < data.data.schools.data.length; i++) {
          //   if (data.data.schools.data[i].status == 'active') {
          //     schooldata.push(data.data.schools.data[i]);
          //   }
          // }
          // this.schools = data.data.schools.data;
        }
      },
        (error) => {
          console.log(error);
          let data = JSON.parse(error._body);
          alert("error: " + data.message);
        });
  }

  resetAndRefreshTable(action = '') {
    // if (this.searchTerm) {
    //   this.tableParams = this.tableParams.set('term', this.searchTerm);
    // }
    // else {
    //   if (this.tableParams.has('term')) {
    //     this.tableParams = this.tableParams.delete('term');
    //   }
    // }
    if (action == 'modal_call_to_action') {
      this.tableCurPage = 1;
    }
    this.tableParams = this.tableParams.set('page', this.tableCurPage.toString());
    this.tableParams = this.tableParams.set('per_page', this.tablePageSize.toString());
    this.getTableData(this.tableParams);
  }

  getTableData(params: HttpParams) {
    if (this.searchTerm != '') {
      params = params.set('term', this.searchTerm);
    }
    if (this.subscriber_id) {
      params = params.set('subscriber_id', this.subscriber_id);
      if (this.site_id) {
        params = params.set('site_id', this.site_id);
      }
      if (this.school_id) {
        params = params.set('school_id', this.school_id);
      }
    }
    if (this.school != '') {
      params = params.set('school_id', this.school);
    }

    if (this.imageCheck != '') {
      params = params.set('image_check', this.imageCheck);
    }

    var url = 'api/student?';
    if (this.export == true) {
      params = params.set('export', 'true');
      this.coreService.downloadRequest(url + params.toString())
        .then(data => {
          // let options = { type: 'text/xlsx;charset=utf-8;' };
          // application/vnd.ms-excel
          let options = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
          var contentDisposition = data.headers.get('Content-Disposition');
          var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          DownloadHelper.createAndDownloadBlobFile(data._body, options, filename);
        }, error => {
          let data = JSON.parse(error._body);
          alert("error: " + data.message);
        });
    } else {
      this.coreService.getRequest(url + params.toString())
        .then(data => {
          for (var i = 0; i < data.data.students.data.length; i++) {
            data.data.students.data[i]['image_safe'] = this.sanitizer.bypassSecurityTrustResourceUrl(data.data.students.data[i].image)
          }
          this.tableRecords = data.data.students.data;
          this.tableTotalRecords = data.data.students.total;
        }, error => {
          console.log(error);
        });
    }
    this.export = false;

  }

  onDelete(id) {
    this.onDeleteType = true;
    this.onResetType = false;
    this.delete_id = id;
    this.modalDetails.modal_title = 'Alert';
    this.modalDetails.modal_body = "Are you sure you want to delete this student ?";
    this.modalDetails.modal_type = "modal_call_to_action";
    this.modalService.open(this.modelContent, { centered: true });
  }

  modalCallToAction() {
    if (this.modalDetails.modal_type == 'modal_call_to_action') {
      if (this.onDeleteType == true) {
        this.DeleteCallToAction();
      }
    }
  }

  DeleteCallToAction() {
    this.modalService.dismissAll();
    if (this.modalDetails.modal_type == 'modal_call_to_action') {
      this.coreService.deleteRequest('api/student/' + this.delete_id)
        .then(data => {
          if (data.success) {
            alert('Successfully Deleted Student');
            this.resetAndRefreshTable();
          }
        }, error => {
          console.log(error);
          alert('Delete Student Fail');
        })
    }
  }

  onAdvancedSearch() {
    let postParams = this.advancedSearchForm.value
    this.searchTerm = postParams.searchTerm;
    this.resetAndRefreshTable();
  }

  getstudent(id) {
    // this.coreService.getRequest('api/student/' + id)
    //     .then((data) => {
    //         if (data && data.success) {
    //             this.studentData = data.data.student;
    //         }
    //     },
    //         (error) => {
    //             console.log(error);
    //             let data = JSON.parse(error._body);
    //         });
  }

  sorting(sortby) {
    this.sortby = sortby;
    if (this.DESC == true) {
      this.DESC = false;
    } else {
      this.DESC = true;
    }
    this.resetAndRefreshTable();
  }

  checkPermissionName(perm_name) {
    let userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
    let data = userRolePerm;

    for (var i = 0; i < data.length; i++) {
      if (perm_name == data[i].permission_name) {
        return true;
      }
    }
  }

  exportReport() {
    this.export = true;
    this.getTableData(this.tableParams);
  }
}
