import { ChangeDetectionStrategy, Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core'; import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CoreService } from '../../core.service';
import { HttpClient } from '@angular/common/http';
import DownloadHelper from '../../download-helper';

@Component({
    selector: 'app-visitor-report',
    templateUrl: './visitor-report.component.html',
    styleUrls: ['./visitor-report.component.css']
})
export class VisitorReportComponent implements OnInit {

    visitor_report_search_filter_Form: FormGroup;
    advancedSearchForm: FormGroup;
    delete_id = -1;
    tableRecords = [];
    tableParams = new HttpParams();
    tableTotalRecords = 0;
    tableCurPage = 1;
    tablePageSize = 10;
    startdate = moment().format('YYYY-MM-DD');
    enddate = moment().add(1, 'day').format('YYYY-MM-DD');
    searchName = '';
    export = false;
    exportPDF = false;
    order = "DESC";
    sortby = "temperature_records.created_at";
    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate | null;
    toDate: NgbDate | null;


    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private coreService: CoreService,
        private sanitizer: DomSanitizer,
        private calendar: NgbCalendar,
        public formatter: NgbDateParserFormatter) {
        this.fromDate = calendar.getToday();
        this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    }

    timeOptions = [
        "00:00:00",
        "00:30:00",
        "01:00:00",
        "01:30:00",
        "02:00:00",
        "02:30:00",
        "03:00:00",
        "03:30:00",
        "04:00:00",
        "04:30:00",
        "05:00:00",
        "05:30:00",
        "06:00:00",
        "06:30:00",
        "07:00:00",
        "07:30:00",
        "08:00:00",
        "08:30:00",
        "09:00:00",
        "09:30:00",
        "10:00:00",
        "10:30:00",
        "11:00:00",
        "11:30:00",
        "12:00:00",
        "12:30:00",
        "13:00:00",
        "13:30:00",
        "14:00:00",
        "14:30:00",
        "15:00:00",
        "15:30:00",
        "16:00:00",
        "16:30:00",
        "17:00:00",
        "17:30:00",
        "18:00:00",
        "18:30:00",
        "19:00:00",
        "19:30:00",
        "20:00:00",
        "20:30:00",
        "21:00:00",
        "21:30:00",
        "22:00:00",
        "22:30:00",
        "23:00:00",
        "23:30:00",
    ];

    startTime = '00:00:00';
    endTime = '00:00:00';

    ngOnInit() {
        this.resetAndRefreshTable();

        this.advancedSearchForm = this.formBuilder.group({
            searchName: ''
        });

        this.visitor_report_search_filter_Form = this.formBuilder.group({
            startdate: [this.startdate],
            enddate: [this.enddate]
        });
    }

    resetAndRefreshTable() {
        // this.startdate = moment(moment(this.fromDate).subtract(1, 'month').format('YYYY-MM-DD') + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss');
        // this.enddate = moment(moment(this.toDate).subtract(1, 'month').format('YYYY-MM-DD') + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss');
        this.startdate = moment(this.formatter.format(this.fromDate) + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss');
        this.enddate = moment(this.formatter.format(this.toDate)  + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss');

        this.tableParams = this.tableParams.set('page', this.tableCurPage.toString());
        this.tableParams = this.tableParams.set('per_page', this.tablePageSize.toString());
        this.getTableData(this.tableParams);
    }

    getTableData(params: HttpParams) {
        var url = 'api/report/get-visitors-report?';
        params = params.set('start_date', this.startdate);
        params = params.set('end_date', this.enddate);
        params = params.set('sort_by', this.sortby);
        params = params.set('order', this.order);
        params = params.set('name', this.searchName);
        // let newParams = params + "&start_date=" + this.startdate + "&end_date="+ this.enddate + "&sort_by=" + this.sortby + "&order=" + this.order + "&name=" + this.searchName

        if (this.export == true) {
            params = params.set('export', 'true');

            this.coreService.downloadRequest(url + params.toString())
                .then(data => {
                    let options = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
                    var contentDisposition = data.headers.get('Content-Disposition');
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    DownloadHelper.createAndDownloadBlobFile(data._body, options, filename);
                }, error => {
                    console.log(error);
                    let data = JSON.parse(error._body);
                    alert("error: " + data.message);
                });
        } else if (this.exportPDF == true) {
            params = params.set('exportPDF', 'true');
            this.coreService.downloadRequest(url + params.toString())
                .then(data => {
                    // let options = { type: 'text/xlsx;charset=utf-8;' };
                    // application/vnd.ms-excel
                    let options = { type: 'application/pdf' };
                    var contentDisposition = data.headers.get('Content-Disposition');
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    DownloadHelper.createAndDownloadBlobFile(data._body, options, filename);
                }, error => {
                    console.log(error);
                    let data = JSON.parse(error._body);
                    alert("error: " + data.message);
                });
        } else {
            this.coreService.getRequest(url + params.toString())
                .then(data => {
                    for (var i = 0; i < data.report_data.data.length; i++) {
                        data.report_data.data[i]['snapshot_safe'] = this.sanitizer.bypassSecurityTrustResourceUrl(data.report_data.data[i].snapshot);
                        data.report_data.data[i]['image_safe'] = this.sanitizer.bypassSecurityTrustResourceUrl(data.report_data.data[i].image);
                        data.report_data.data[i]['date_formatted'] = moment(data.report_data.data[i].datetime).format('DD/MM/YYYY');
                        data.report_data.data[i]['time_formatted'] = moment(data.report_data.data[i].datetime).format('HH : mm : ss');
                        data.report_data.data[i]['type'] = this.renderStatusText(data.report_data.data[i]['type']);
                    }
                    this.tableRecords = data.report_data.data;
                    this.tableTotalRecords = data.report_data.total;
                }, error => {
                    console.log(error);
                });
        }
        this.export = false;
        this.exportPDF = false;

    }

    renderStatusText(status) {
        if (status == "check_in") {
            return "CHECK IN";
        } else if (status == "check_out") {
            return "CHECK OUT";
        }
    }

    onAdvancedSearch() {
        this.export = false;
        this.exportPDF = false;
        let postParams = this.advancedSearchForm.value;
        this.searchName = postParams.searchName;
        this.resetAndRefreshTable();
    }

    sorting(sortby) {
        this.sortby = sortby;
        if (this.order == 'DESC') {
            this.order = 'ASC';
        } else {
            this.order = 'DESC';
        }
        this.resetAndRefreshTable();
    }

    onFilter(params: HttpParams) {
        this.export = false;
        this.exportPDF = false;
        // this.startdate = this.employee_report_search_filter_Form.value.startdate
        // this.enddate = this.employee_report_search_filter_Form.value.enddate
        // this.enddateQuery = moment(this.enddate).add(1, 'day').format('YYYY-MM-DD')
        this.startdate = moment(this.formatter.format(this.fromDate) + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss');
        this.enddate = moment(this.formatter.format(this.toDate)  + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss');
        this.resetAndRefreshTable();
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || this.fromDate == date)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    exportReport() {
        this.export = true;
        this.exportPDF = false;
        this.getTableData(this.tableParams);
    }

    exportPdfReport() {
        this.exportPDF = true;
        this.export = false;
        this.getTableData(this.tableParams);
    }
}
