import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './password/change-password/change-password.component';
import { VisitorComponent } from './visitor/visitor.component';
import { ApproveVisitorComponent } from './visitor/approve-visitor/approve-visitor.component';
import { VisitorInfoComponent } from './visitor/visitor-info/visitor-info.component';
import { VisitorPendingComponent } from './visitor/visitor-pending/visitor-pending.component';
import { VisitorRegistrationComponent } from './visitor/visitor-registration/visitor-registration.component';
import { BatchUploadVisitorComponent } from './visitor/batch-upload-visitor/batch-upload-visitor.component';
import { StudentComponent } from './student/student.component';
import { AddStudentComponent } from './student/add-student/add-student.component';
import { EditStudentComponent } from './student/edit-student/edit-student.component';
import { BatchUploadStudentComponent } from './student/batch-upload-student/batch-upload-student.component';
import { StudentAttendanceComponent } from './student/student-attendance/student-attendance.component';
import { StudentPublicPhotoUploadComponent } from './student-public-photo-upload/student-public-photo-upload.component';
import { VisitorReportComponent } from './report/visitor-report/visitor-report.component';
import { StudentAttendanceReportComponent } from './report/student-attendance-report/student-attendance-report.component';
import { LessonComponent } from './lesson/lesson.component';
import { AddLessonComponent } from './lesson/add-lesson/add-lesson.component';
import { EditLessonComponent } from './lesson/edit-lesson/edit-lesson.component';
import { BatchUploadEmployeeComponent } from './employee/batch-upload-employee/batch-upload-employee.component';
import { PublicVisitorRegistrationComponent } from './visitor/public-visitor-registration/public-visitor-registration.component';
import { PublicImageUploadComponent } from './public-image-upload/public-image-upload.component';
import { EventComponent } from './event/event.component';
import { BatchSendEmailEventComponent } from './event/batch-send-email-event/batch-send-email-event.component';
import { EditEventComponent } from './event/edit-event/edit-event.component';
import { AddEventComponent } from './event/add-event/add-event.component';
import { EmployeeComponent } from './employee/employee.component';
import { AddEmployeeComponent } from './employee/add-employee/add-employee.component';
import { EditEmployeeComponent } from './employee/edit-employee/edit-employee.component';

const routes: Routes = [
  	{ path: '', redirectTo: 'student/list', pathMatch: 'full' },
	{
	// 	path: 'dashboard',
	// 	component: MainLayoutComponent,
	// 	children: [
	// 		{ path: '', component: DashboardComponent }
	// 	],
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		roles: ['admin', 'super_admin', 'hr'],
	// 		pageTitle: 'Dashboard',
	// 		pageSubTitle: 'Welcome to Genesis Thermal Facial Dashboard'
	// 	}
	// },{
		path: 'login',
		component: LoginComponent,
		// children: [
		//   { path: '', component: DashboardComponent }
		// ],
		data: {
			pageTitle: 'Login',
			// pageSubTitle: 'Welcome to Genesis Thermal Facial Dashboard'
		}
	},{
		path: 'password/change-password',
		component: MainLayoutComponent,
		children: [
			{ path: '', component: ChangePasswordComponent }
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Change Password'
		}
	},{
		path: 'student/list',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: StudentComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Students',
			permission: 'view-student'
			//   pageSubTitle: ''
		}
	},{
		path: 'student/add-student',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: AddStudentComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Add Student',
			permission: 'create-student'
			//   pageSubTitle: ''
		}
	},{
		path: 'student/edit-student/:id',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: EditStudentComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Edit Student',
			permission: 'edit-student'
			//   pageSubTitle: ''
		}
	},{
		path: 'student/batch-upload',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: BatchUploadStudentComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Batch Upload',
      permission: 'create-student'
		}
	},{
		path: 'visitor/list',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: VisitorComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Visitors',
			permission: 'view-visit-request'
			//   pageSubTitle: ''
		}
	},{
		path: 'visitor/visitor-pending',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: VisitorPendingComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Pending Visitor',
			permission: 'view-visit-request'
			//   pageSubTitle: ''
		}
	},{
		path: 'visitor/registration',
		// component: VisitorRegistrationComponent,
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: VisitorRegistrationComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Visitor Registration',
			permission: 'create-visit-request'
			//   pageSubTitle: ''
		}
	},{
		path: 'visitor/visitor-info/:id',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: VisitorInfoComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Visitor Info',
			permission: 'view-visit-request'
			//   pageSubTitle: ''
		}
	},{
		path: 'visitor/approve-visitor',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: ApproveVisitorComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Visitor Approval',
			permission: 'edit-visit-request'
			//   pageSubTitle: ''
		}
	},{
		path: 'visitor/batch-upload',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: BatchUploadVisitorComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Batch Upload',
			permission: 'create-visit-request'
		}
	},{
		path: 'student/attendance',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: StudentAttendanceComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Student Attendance',
			permission: 'view-stp-attendance'
			//   pageSubTitle: ''
		}
	},{
		path: 'student/lesson',
		component: MainLayoutComponent,
		children: [
			{ path: '', component: LessonComponent }
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Programmes',
			permission: 'view-school-module-schedule'
			//   pageSubTitle: ''
		}
	},{
		path: 'student/lesson/add-lesson',
		component: MainLayoutComponent,
		children: [
			{ path: '', component: AddLessonComponent }
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Add Programme',
			permission: 'create-school-module-schedule'
			//   pageSubTitle: ''
		}
	},{
		path: 'student/lesson/edit-lesson/:id',
		component: MainLayoutComponent,
		children: [
			{ path: '', component: EditLessonComponent }
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Edit Programme',
			permission: 'edit-school-module-schedule'
			//   pageSubTitle: ''
		}
	},{
		path: 'employee/list',
		component: MainLayoutComponent,
		children: [
			{ path: '', component: EmployeeComponent }
		],
		canActivate: [AuthGuard],
		data: {
      pageTitle: 'Employees',
      permission: 'view-employee'
		}
	},{
		path: 'employee/add-employee',
		component: MainLayoutComponent,
		children: [
			{ path: '', component: AddEmployeeComponent }
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Add Employee',
      permission: 'create-employee'
		}
	},{
		path: 'employee/edit-employee/:id',
		component: MainLayoutComponent,
		children: [
			{ path: '', component: EditEmployeeComponent }
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Edit Employee',
      permission: 'edit-employee'
		}
	},{
		path: 'employee/batch-upload',
		component: MainLayoutComponent,
		children: [
			{ path: '', component: BatchUploadEmployeeComponent }
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Batch Upload',
      permission: 'create-employee'
		}
	}, {
	// 	path: 'event/list',
	// 	component: MainLayoutComponent,
	// 	children: [
	// 		{
	// 			path: '', component: EventComponent
	// 		}
	// 	],
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		pageTitle: 'Events',
	// 		// permission: 'view-student'
	// 		//   pageSubTitle: ''
	// 	}
	// },{
	// 	path: 'event/add-event',
	// 	component: MainLayoutComponent,
	// 	children: [
	// 		{
	// 			path: '', component: AddEventComponent
	// 		}
	// 	],
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		pageTitle: 'Add Event',
	// 		// permission: 'view-student'
	// 		//   pageSubTitle: ''
	// 	}
	// },{
	// 	path: 'event/batch-send-email/:id',
	// 	component: MainLayoutComponent,
	// 	children: [
	// 		{
	// 			path: '', component: BatchSendEmailEventComponent
	// 		}
	// 	],
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		pageTitle: 'Event Batch Send Email',
	// 		// permission: 'view-student'
	// 		//   pageSubTitle: ''
	// 	}
	// },{
	// 	path: 'event/edit-event/:id',
	// 	component: MainLayoutComponent,
	// 	children: [
	// 		{
	// 			path: '', component: EditEventComponent
	// 		}
	// 	],
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		pageTitle: 'Edit Event',
	// 		// permission: 'view-student'
	// 		//   pageSubTitle: ''
	// 	}
	// },{
		path: 'upload-image/:type',
		component: PublicImageUploadComponent,
		data: {
			pageTitle: 'Upload Image',
		}
	},{
		path: 'visitor/public-registration',
		component: PublicVisitorRegistrationComponent,
		data: {
			pageTitle: 'Public Visitor Registration',
		}
	},{
		path: 'student-upload-photo',
		component: StudentPublicPhotoUploadComponent,
		data: {
			pageTitle: 'Upload Photo',
		}
	},{
		path: 'report/student-attendance',
		component: MainLayoutComponent,
		children: [
			{
				path: '', component: StudentAttendanceReportComponent
			}
		],
		canActivate: [AuthGuard],
		data: {
			pageTitle: 'Student Attendance Report',
      permission: 'view-report-schedule'
		}
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
