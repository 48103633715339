import { ChangeDetectionStrategy, Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core'; import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CoreService } from '../core.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import DownloadHelper from '../download-helper';
import { EmployeeParameterService } from './employee-parameter.service';


@Component({
    selector: 'app-employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
    userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
    advancedSearchForm: FormGroup;
    editRoleForm: FormGroup;
    delete_id = -1;
    onDeleteType = false;
    onResetType = false;
    reset_id = -1;
    tableRecords = [];
    tableParams = new HttpParams();
    // tableTotalRecords = 0;
    // tableCurPage = 1;
    // tablePageSize = 20;
    sortby = '';
    DESC = false;
    employeeData: [];
    departments = [];
    // department = '';
    searchName = '';
    // searchTerm = '';
    subscriber_id = (localStorage.getItem('curSubscriber') !== null) ? localStorage.getItem('curSubscriber') : JSON.parse(localStorage.getItem('user_login_data')).user.subscriber_id;
    school_id = localStorage.getItem('curSchool');
    export = false;

    @ViewChild('modal_content', { static: false })
    private modelContent!: TemplateRef<any>;
    modal_body: string = '';
    modal_title: string = '';
    modalDetails: any = {
        modal_title: '',
        modal_body: '',
        modal_type: '',
        modal_action: ''
    };

    @ViewChild('modal_content_role', { static: false })
    private modelContentRole!: TemplateRef<any>;

    roles = [];
    roleId = 4;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private modalService: NgbModal,
        private coreService: CoreService,
        private sanitizer: DomSanitizer,
        private employeeParameterService: EmployeeParameterService
    ) { }

    ngOnInit() {
        this.advancedSearchForm = this.formBuilder.group({
            department: [this.department],
            searchTerm: ['']
        });
        this.editRoleForm = this.formBuilder.group({
            user_id: ['', Validators.required],
            role_id: ['', Validators.required]
        });
        this.getDepartments();
        this.getRoles();
        this.resetAndRefreshTable();
    }

    get department(): string {
        return this.employeeParameterService.department;
    }

    set department(value: string) {
        this.employeeParameterService.department = value;
    }

    get searchTerm(): string {
        return this.employeeParameterService.searchTerm;
    }

    get imageCheck(): string {
        return this.employeeParameterService.imageCheck;
    }

    set imageCheck(value: string) {
        this.employeeParameterService.imageCheck = value;
    }

    set searchTerm(value: string) {
        this.employeeParameterService.searchTerm = value;
    }

    get tableCurPage(): number {
        return this.employeeParameterService.page;
    }

    set tableCurPage(value: number) {
        this.employeeParameterService.page = value;
    }

    get tablePageSize(): number {
        return this.employeeParameterService.perPage;
    }

    set tablePageSize(value: number) {
        this.employeeParameterService.perPage = value;
    }

    get tableTotalRecords(): number {
        return this.employeeParameterService.totalRecords;
    }

    set tableTotalRecords(value: number) {
        this.employeeParameterService.totalRecords = value;
    }

    getDepartments() {
        let url = 'api/department';

        if (this.subscriber_id) {
            url += '?subscriber_id=' + this.subscriber_id;
            if (this.school_id) {
                url += '&school_id=' + this.school_id;
            }
        }

        this.coreService.getRequest(url)
            .then((data) => {
                if (data && data.success) {
                    // this.departments = data.data.departments.data;
                    let departmentdata = [];
                    for (var i = 0; i < data.data.departments.data.length; i++) {
                        if (data.data.departments.data[i].status == 'active') {
                            departmentdata.push(data.data.departments.data[i]);
                        }
                    }
                    this.departments = departmentdata;
                }
            },
                (error) => {
                    console.log(error);
                    let data = JSON.parse(error._body);
                    alert("error: " + data.message);
                });
    }

    async getRoles() {
        let url = 'api/user/roles?psb-admin=true';

        this.coreService.getRequest(url)
            .then(async (data) => {
                if (data && data.success) {
                    this.roles = await data.data.roles;
                }
            },
            (error) => {
                console.log(error);
                let data = JSON.parse(error._body);
                alert("error: " + data.message);
            });
    }

    resetAndRefreshTable(action = '') {
        // if (this.searchTerm) {
        //   this.tableParams = this.tableParams.set('term', this.searchTerm);
        // }
        // else {
        //   if (this.tableParams.has('term')) {
        //     this.tableParams = this.tableParams.delete('term');
        //   }
        // }
        if (action == 'modal_call_to_action') {
            this.tableCurPage = 1;
        }
        this.tableParams = this.tableParams.set('page', this.tableCurPage.toString());
        this.tableParams = this.tableParams.set('per_page', this.tablePageSize.toString());
        this.getTableData(this.tableParams);
    }

    getTableData(params: HttpParams) {
        if (this.department != '') {
            params = params.set('department', this.department);
        }
        if (this.searchTerm != '') {
            params = params.set('term', this.searchTerm);
        }
        if (this.subscriber_id) {
            params = params.set('subscriber_id', this.subscriber_id);
            if (this.school_id) {
                params = params.set('school_id', this.school_id);
            }
        }
        if (this.imageCheck != '') {
            params = params.set('image_check', this.imageCheck);
        }
        // params = params.set('department', '1');
        var url = 'api/staff?' + params.toString();

        if (this.export == true) {
            params = params.set('export', 'true');
            this.coreService.downloadRequest(url + params.toString())
                .then(data => {
                    // let options = { type: 'text/xlsx;charset=utf-8;' };
                    // application/vnd.ms-excel
                    let options = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
                    var contentDisposition = data.headers.get('Content-Disposition');
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    DownloadHelper.createAndDownloadBlobFile(data._body, options, filename);
                }, error => {
                    let data = JSON.parse(error._body);
                    alert("error: " + data.message);
                });
        } else {
            this.coreService.getRequest(url)
                .then(data => {
                    for (var i = 0; i < data.data.employees.data.length; i++) {
                        data.data.employees.data[i]['image_safe'] = this.sanitizer.bypassSecurityTrustResourceUrl(data.data.employees.data[i].image);
                    }
                    this.tableRecords = data.data.employees.data;
                    this.tableTotalRecords = data.data.employees.total;
                }, error => {
                    console.log(error);
                });
        }
        this.export = false;
    }

    checkPermissionName(perm_name) {
        let userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
        let data = userRolePerm;

        for (var i = 0; i < data.length; i++) {
            if (perm_name == data[i].permission_name) {
                return true;
            }
        }
    }

    checkDeletePermissionName(perm_name) {
        let userData = JSON.parse(localStorage.getItem('user_login_data'));
        let userRole = userData.role[0].name
        if (userRole == "super_admin") {
            return true;
        } else {
            return false;
        }
    }

    checkFontColor(status) {
        if (status == "active") {
            return '<div style="font-color:green>Active</div>';
        } else if (status == "inactive") {
            return '<div style="font-color:red>Inactive</div>';
        } else {
            return status;
        }
    }

    checkDepartment(d_id) {
        let data = this.departments
        for (var i = 0; i < data.length; i++) {
            if (d_id == data[i].id) {
                return data[i].name;
            }
        }
    }

    capitalize(text) {
        return text[0].toUpperCase() + text.slice(1);
    }

    async onUpdateRole(user_id, role_id) {
        this.modalDetails.modal_type = "modal_call_to_action";
        this.modalDetails.modal_action = "update_role";
        this.editRoleForm.patchValue({
            user_id: user_id,
            role_id: role_id
        });
        console.log(this.editRoleForm.value);
        this.modalService.open(this.modelContentRole, { centered: true });
    }

    onDelete(id) {
        // this.onDeleteType = true;
        // this.onResetType = false;
        this.delete_id = id;
        this.modalDetails.modal_title = 'Alert';
        this.modalDetails.modal_body = "Are you sure you want to delete this employee ?";
        this.modalDetails.modal_type = "modal_call_to_action";
        this.modalDetails.modal_action = "delete";
        this.modalService.open(this.modelContent, { centered: true });
    }

    getEmployee(id) {
        this.coreService.getRequest('api/employee/' + id)
            .then((data) => {
                if (data && data.success) {
                    this.employeeData = data.data.employee;
                }
            },
                (error) => {
                    console.log(error);
                    let data = JSON.parse(error._body);
                });
    }

    onResetUserPassword(id, name) {
        // this.onResetType = true;
        // this.onDeleteType = false;
        this.getEmployee(id);
        this.reset_id = id;
        this.modalDetails.modal_title = 'Alert';
        this.modalDetails.modal_body = "Are you sure you want to reset password for " + name + " ?";
        this.modalDetails.modal_type = "modal_call_to_action";
        this.modalDetails.modal_action = "reset";
        this.modalService.open(this.modelContent, { centered: true });
    }

    modalCallToAction() {
        if (this.modalDetails.modal_type == 'modal_call_to_action') {
            if (this.modalDetails.modal_action == 'reset') {
                this.ResetCallToAction();
            } else if (this.modalDetails.modal_action == 'delete') {
                this.DeleteCallToAction();
            } else if (this.modalDetails.modal_action == 'update_role') {
                this.UpdateRoleCallToAction();
            }
        }
    }

    DeleteCallToAction() {
        this.modalService.dismissAll();
        if (this.modalDetails.modal_type == 'modal_call_to_action') {
            this.coreService.deleteRequest('api/staff/' + this.delete_id)
                .then(data => {
                    if (data.success) {
                        alert('Successfully Deleted Employee');
                        this.resetAndRefreshTable();
                        // location.reload();
                    }
                }, error => {
                    console.log(error);
                    alert('Delete Employee Fail');
                })
        }
    }

    ResetCallToAction() {
        this.modalService.dismissAll();
        let params = new HttpParams;
        params = params.set('email', this.employeeData['email']);
        let email = this.employeeData['email'];
        let postParams = { 'email': email };
        if (this.modalDetails.modal_type == 'modal_call_to_action') {
            this.coreService.postRequest('api/user/password_reset/request?', postParams)
                .then(data => {
                    if (data.message) {
                        alert('Successfully Reset Employee Password. ' + 'We have sent the password reset link to the email');
                        this.resetAndRefreshTable();
                        // location.reload();
                    }
                }, error => {
                    console.log(error);
                    alert('Reset Employee Password Fail');
                })
        }
    }

    UpdateRoleCallToAction() {
        this.modalService.dismissAll();
        if (this.modalDetails.modal_type == 'modal_call_to_action') {
            this.coreService.postRequest('api/user/update_role/' + this.editRoleForm.value.user_id, {role_id: this.editRoleForm.value.role_id})
                .then(data => {
                    if (data.success) {
                        alert('Successfully Update Role of Employee');
                        this.resetAndRefreshTable();
                        // location.reload();
                    }
                }, error => {
                    console.log(error);
                    alert('Update Employee Role Fail');
                });
        }
    }

    onAdvancedSearch() {
        let postParams = this.advancedSearchForm.value
        this.searchTerm = postParams.searchTerm;
        this.resetAndRefreshTable();
    }

    sorting(sortby) {
        this.sortby = sortby;
        if (this.DESC == true) {
            this.DESC = false;
        } else {
            this.DESC = true;
        }
        this.resetAndRefreshTable();
    }

    exportReport() {
        this.export = true;
        this.getTableData(this.tableParams);
    }
}
