import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { CoreService } from '../../core.service';
import { StudentParameterService } from '../student-parameter.service';

@Component({
  selector: 'app-edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.css']
})
export class EditStudentComponent implements OnInit {
  updateStudentForm: FormGroup;
  submitted = false;
  studentData: any = [];
  imgURL: any;
  imgBase64: any = '';
  genders: any = [
    {
      type: 'M',
      name: "Male"
    }, {
      type: 'F',
      name: "Female"
    }
  ];
  programmes: any = [];
  schools: any = [];

  subscribers = JSON.parse(localStorage.getItem('subscribers'));
  subscriber_id = localStorage.getItem('curSubscriber');
  sites: any = [];
  selectedSite: any = [];
  selectAllCheck: Boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private studentParameterService: StudentParameterService
  ) { }

  ngOnInit() {
    this.updateStudentForm = this.formBuilder.group({
      name: ['', Validators.required],
      sex: ['', Validators.required],
      email: ['', Validators.required],
      mobile: '',
      student_id: {value: '', disabled: true},
      nationality: {value: '', disabled: true},
      citizenship: {value: '', disabled: true},
      image: '',
      subscriber_id: ['', Validators.required],
      // course_id: '',
      school_id: ['', Validators.required],
      status: ['', Validators.required],
    });

    this.updateStudentForm.patchValue({
      subscriber_id: this.subscriber_id
    })

    this.coreService.getRequest('api/student/' + this.route.snapshot.params['id'])
    .then((data) => {
      if (data && data.success) {
        data.data.student.image_safe = this.sanitizer.bypassSecurityTrustResourceUrl(data.data.employee.image);
        data.data.student.name = data.data.employee.name;
        data.data.student.status = (data.data.student.status).toLowerCase();
        // data.data.student.course_id = data.data.course.id;
        this.studentData = data.data.student;
        this.getSites(this.subscriber_id);
      }
      var patchValue = this.studentData;
      patchValue['image'] = '';
      this.updateStudentForm.patchValue(patchValue);
      this.imgURL = this.studentData['image_safe'];
      this.getSchools();
    },
    (error) => {
      console.log(error);
      let data = JSON.parse(error._body);
    });
  }

  get f() { return this.updateStudentForm.controls; }

  getSites(subscriber_id) {
    this.coreService.getRequest('api/site?subscriber_id='+subscriber_id)
    .then((data) => {
      if (data && data.success) {
        this.sites = data.data.sites.data;
        let studentSites = JSON.parse(this.studentData.sites);
        let groupSite = [];
        for (var i = 0; i < studentSites.length; i++) {
          for (var j = 0; j < this.sites.length; j++) {
            if(studentSites[i] == this.sites[j].id){
              groupSite.push(this.sites[j].id);
              // console.log(this.sites[j].id);
              break;
            }
          }
        }
        this.selectedSite = groupSite;
        // this.getProgrammes();
      }
    },(error) => {
        let data = JSON.parse(error._body);
        console.log("error: " + data.errors[0]);
    });
  }

  getProgrammes() {
    if(this.selectedSite.length > 0){
      this.coreService.getRequest('api/school_course?subscriber_id='+this.subscriber_id+'&sites='+JSON.stringify(this.selectedSite))
      .then((data) => {
        if (data && data.success) {
          this.programmes = data.data.school_courses.data;
        }
      },(error) => {
          let data = JSON.parse(error._body);
          console.log("error: " + data.errors[0]);
      });
    }else{
      this.programmes = [];
      this.updateStudentForm.patchValue({
        course_id: ''
      });
    }
  }

  getSchools() {
    this.coreService.getRequest('api/school')
    .then((data) => {
      if (data && data.success) {
        this.schools = data.data.schools.data;
      }
    },(error) => {
        let data = JSON.parse(error._body);
        console.log("error: " + data.errors[0]);
    });
  }

  onSubmit() {
    this.submitted = true;
    let postParams = this.updateStudentForm.value;
    if (this.updateStudentForm.valid) {
      if (this.imgBase64) {
        postParams.image = this.imgBase64;
      }
      else {
        delete postParams.image;
      }
      postParams.site_ids = JSON.stringify(this.selectedSite);

      this.coreService.putRequest('api/student/' + this.route.snapshot.params['id'], postParams)
      .then((data) => {
        alert('Student Profile Updated');
        this.studentParameterService.resetParameterService();
        this.router.navigate(['/student/list']);
      },
      (error) => {
        console.log(error);
        let data = JSON.parse(error._body);
        alert("error: " + data.errors[0]);
      })
    }
  }

  onSelectAll(type) {
    let groupSite = [];
    if(this.selectAllCheck){
      for (var i = 0; i < this.sites.length; i++) {
        groupSite.push(this.sites[i].id);
      }
      this.getProgrammes();
    }
    this.selectedSite = groupSite;
  }

  preview(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgBase64 = event.target['result'];
        this.imgURL = event.target['result'];
      }
    }
  }
}
