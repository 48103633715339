import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-visitor-registration',
  templateUrl: './visitor-registration.component.html',
  styleUrls: ['./visitor-registration.component.css']
})
export class VisitorRegistrationComponent implements OnInit {
  visitorRegistrationForm: FormGroup;
  submitted = false;
  genders = [{ 
    type: 'M',
    name: "Male" 
  }, { 
    type: 'F',
    name: "Female" 
  }];
  purposes: any = [];
  // purposes = [
  //   "Delivery",
  //   "Collection",
  //   "Servicing",
  //   "Meeting"
  // ];
  startdate = new Date(moment().startOf('day').valueOf());
  enddate = new Date(moment().add(1, 'd').startOf('day').valueOf());
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  timeOptions = [
    "00:00:00",
    "00:30:00",
    "01:00:00",
    "01:30:00",
    "02:00:00",
    "02:30:00",
    "03:00:00",
    "03:30:00",
    "04:00:00",
    "04:30:00",
    "05:00:00",
    "05:30:00",
    "06:00:00",
    "06:30:00",
    "07:00:00",
    "07:30:00",
    "08:00:00",
    "08:30:00",
    "09:00:00",
    "09:30:00",
    "10:00:00",
    "10:30:00",
    "11:00:00",
    "11:30:00",
    "12:00:00",
    "12:30:00",
    "13:00:00",
    "13:30:00",
    "14:00:00",
    "14:30:00",
    "15:00:00",
    "15:30:00",
    "16:00:00",
    "16:30:00",
    "17:00:00",
    "17:30:00",
    "18:00:00",
    "18:30:00",
    "19:00:00",
    "19:30:00",
    "20:00:00",
    "20:30:00",
    "21:00:00",
    "21:30:00",
    "22:00:00",
    "22:30:00",
    "23:00:00",
    "23:30:00"
  ];

  startTime = moment().format("HH")+':00:00';
  endTime = moment().add(1, 'hour').format("HH")+':00:00';

  visitor_types: any = [];
  sites = JSON.parse(localStorage.getItem('sites'));
  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    this.fromDate = calendar.getToday();
    // this.toDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    // this.startdate = moment(this.formatter.format(this.fromDate)).format('YYYY-MM-DD');
    // this.enddate = moment(this.formatter.format(this.toDate)).format('YYYY-MM-DD');
  }

  ngOnInit() {
    // this.startdate = moment(this.formatter.format(this.fromDate) + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss');
    // this.enddate = moment(this.formatter.format(this.toDate)  + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss');

    this.visitorRegistrationForm = this.formBuilder.group({
      subscriber_id: this.subscriber_id,
      name: ['', Validators.required],
      sex: ['', Validators.required],
      email: ['', Validators.required],
      mobile: '',
      type: ['', Validators.required],
      purpose: ['', Validators.required],
      site_id: ['', Validators.required],
      is_single_day_use: false,
      remarks: '',
      visit_datetime: this.formBuilder.array([])
    });

    this.getVisitorTypes();
    this.getVisitPurposes();
  }
  get f() { return this.visitorRegistrationForm.controls; }

  get fa(): FormArray {
    return this.visitorRegistrationForm.get('visit_datetime') as FormArray;
  }

  addDateTime() {
    const creds = this.visitorRegistrationForm.controls.visit_datetime as FormArray;
    creds.push(this.formBuilder.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required]
    }));
  }

  removeDateTime() {
    const creds = this.visitorRegistrationForm.controls.visit_datetime as FormArray;
    if(creds.length > 0){
      creds.removeAt(creds.length - 1);  
    }
  }

  getVisitorTypes() {
    var url = 'api/visitor_type?subscriber_id='+this.subscriber_id;
    this.coreService.getRequest(url)
    .then(data => {
      if(data && data.success) {
        this.visitor_types = data.data.departments;  
      }
    }, error => {
        console.log(error);
    });
  }

  getVisitPurposes() {
    let url = 'api/visit_purpose';
    if(this.subscriber_id){
      url += '?subscriber_id='+this.subscriber_id;
    }
    this.coreService.getRequest(url)
    .then(result => {
      if(result && result.success) {
        this.purposes = result.data.visit_purposes.data;
      }
    }, error => {
        console.log(error);
    });
  }

  parseDateToString(dateObj: moment.MomentInput) {
    return moment(dateObj).format('YYYY-MM-DD');
  }

  onSubmit() {
    // this.startdate = moment(this.formatter.format(this.fromDate) + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss');
    // this.enddate = moment(this.formatter.format(this.toDate)  + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss');

    this.submitted = true;
    if(this.visitorRegistrationForm.controls.visit_datetime['controls'].length == 0){
      return;
    }
    let postParams = JSON.parse(JSON.stringify(this.visitorRegistrationForm.value));
    postParams.visit_datetime = postParams.visit_datetime.map((datetime: { start_date: moment.MomentInput; end_date: moment.MomentInput; }) => {
      datetime.start_date = this.parseDateToString(datetime.start_date);
      datetime.end_date = this.parseDateToString(datetime.end_date);
      return datetime;
    });

    if (this.visitorRegistrationForm.valid) {
      this.coreService.postRequest('api/visit_request', postParams)
      .then((data) => {
        alert('Visitor Registration Created Successfully!');
        this.router.navigate(['/visitor/list']);
      },
      (error) => {
        console.log(error);
        let data = JSON.parse(error._body);
        alert("error: " + data.errors[0]);
      })
    }
  }
  
  onFromDateSelection(fromdate: NgbDate) {
    this.fromDate = fromdate;
  }
  onToDateSelection(todate: NgbDate){
    this.toDate = todate;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || this.fromDate == date)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
