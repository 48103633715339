import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent implements OnInit {

  editEventForm: FormGroup;
  submitted = false;
  message: string;

  currentDate: Date;
  timeOptions: Array<string>= [];
  timeMinutes: Array<string> = [
    "00",
    "30"
  ];
  
  eventId = this.route.snapshot.params['id'];
  subscriber_id = localStorage.getItem('curSubscriber');
  sites: any = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.currentDate = new Date(moment().startOf('day').valueOf());
  }

  ngOnInit() {
    this.editEventForm = this.formBuilder.group({
      site_id: ['', Validators.required],
      name: ['', Validators.required],
      description: '',
      limit: ['', [Validators.required, Validators.min(10), Validators.max(1000)]],
      date: ['', Validators.required],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      subscriber_id: ['', Validators.required]
    });

    this.getSites(this.subscriber_id);
    this.timeOptions = this.generateTimeOptions(this.timeMinutes);

    this.getEvent(this.eventId);

  }

  generateTimeOptions(minutesArray: Array<string>): Array<string> {
    let retTimeOptions: Array<string> = [];
    for(let i: number = 0; i < 24; i++) {
      //hour

      let hourText: string = '';
      if(i < 10) {
        hourText += '0' + String(i);
      } else {
        hourText = String(i);
      }

      let secondText: string = '00';
      for(let j: number = 0; j < minutesArray.length; j++) {
        //minute
        
        let minuteText: string = this.timeMinutes[j];
        retTimeOptions.push(`${hourText}:${minuteText}:${secondText}`);
      }
    }

    return retTimeOptions;
  }

  getEvent(event_id: string) {

    this.coreService.getRequest('api/event/' + event_id)
    .then((result) => {
      if (result && result.success && result.data && result.data.event) {
        let patchValue = {
          site_id: result.data.event.site_id,
          name: result.data.event.name,
          description: result.data.event.description,
          limit: result.data.event.limit,
          date: new Date(moment(result.data.event.date).valueOf()),
          start_time: result.data.event.start_time,
          end_time: result.data.event.end_time,
          subscriber_id: result.data.event.subscriber_id,
        };
        this.editEventForm.patchValue(patchValue);
      }
    })
    .catch((error) => {
      console.log(error);
      if(error._body) {
        let data = JSON.parse(error._body);
        let msg = data.errors;
        if(data.errors && data.errors.length > 0) {
          msg = data.errors.join('\n');
        }
        alert("error: " + msg);
      }
    });
  }

  getSites(subscriber_id: string) {
    this.coreService.getRequest('api/site?subscriber_id='+subscriber_id)
    .then((data) => {
      if (data && data.success) {
        this.sites = data.data.sites.data;
      }
    })
    .catch((error) => {
      console.log(error);
      if(error._body) {
        let data = JSON.parse(error._body);
        let msg = data.errors;
        if(data.errors && data.errors.length > 0) {
          msg = data.errors.join('\n');
        }
        alert("error: " + msg);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    let postParams = JSON.parse(JSON.stringify(this.editEventForm.value));
    postParams.date = moment(postParams.date).format('YYYY-MM-DD');

    // stop here if form is invalid
    if (this.editEventForm.valid) {
      this.coreService.putRequest('api/event/' + this.eventId, postParams)
      .then((result) => {
        if(result && result.success) {
          alert('Event Details Updated');
          this.router.navigate(['/event/list']);
        }
      })
      .catch((error) => {
        console.log(error);
        if(error._body) {
          let data = JSON.parse(error._body);
          let msg = data.errors;
          if(data.errors && data.errors.length > 0) {
            msg = data.errors.join('\n');
          }
          alert("error: " + msg);
        }
      });
    }
  }

  onReset() {
    this.submitted = false;
    this.editEventForm.reset();
  }
}
