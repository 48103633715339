import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import DownloadHelper from '../../download-helper';

@Component({
  selector: 'app-batch-send-email-event',
  templateUrl: './batch-send-email-event.component.html',
  styleUrls: ['./batch-send-email-event.component.css']
})
export class BatchSendEmailEventComponent implements OnInit {
    batchSendEmailForm: FormGroup;
    files: any = [];
    errors: any = [];
    errorIsObject = false;
    submitted: boolean = false;

    fieldToDisable: Array<string> = [
      'name',
      'slots'
    ];

    event_id: string = this.route.snapshot.params['id'];
    constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      public coreService: CoreService,
      private route: ActivatedRoute,
      private sanitizer: DomSanitizer
    ) { }
  
    ngOnInit() {
      this.batchSendEmailForm = this.formBuilder.group({
        name: [''],
        slots: [''],
        file: ['', Validators.required],
      });

      this.getEvent(this.event_id);

      for(let key of this.fieldToDisable) {
        if(this.batchSendEmailForm.controls[key]) {
          this.batchSendEmailForm.controls[key].disable();
        }
      }
    }

    getEvent(event_id: string) {
      this.coreService.getRequest('api/event/' + event_id)
      .then(async (result) => {
        if (result && result.success && result.data && result.data.event) {

          let patchValue: any = {
            site_id: result.data.event.site_id,
            name: result.data.event.name
          };

          this.batchSendEmailForm.patchValue(patchValue);
        }
      })
      .catch((error) => {
        console.log(error);
        if(error._body) {
          let data = JSON.parse(error._body);
          let msg = data.errors;
          if(data.errors && data.errors.length > 0) {
            msg = data.errors.join('\n');
          }
          alert("error: " + msg);
        }
      });
    }

    setFile(files){
      if (files.length === 0) {
        return;
      }
      this.updateFileNames(files);
      this.files = files;
    }

    updateFileNames(files) {
      let fileNames = Array.from(files).map((file: any) => file.name).join(', ');
      let customFileLabel = document.getElementById('customFileLabel');
      customFileLabel.innerHTML = fileNames || 'Choose File';
    }

  
    onDownload() {
      this.coreService.downloadRequest('api/upload_template?type=event')
      .then(data => {
        // application/zip
        let options = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
        var contentDisposition = data.headers.get('Content-Disposition');
        var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        DownloadHelper.createAndDownloadBlobFile(data._body, options, filename);
      }, error => {
        let data = JSON.parse(error._body);
        alert("error: " + data.message);
      });
    }
  
    onUpload() {
      this.submitted = true;
      let fileList: FileList = this.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        let formData: FormData = new FormData();
        formData.append('event_id', this.event_id);
        formData.append('excel', file, file.name);
        formData.append('filetype', 'xlsx');
        formData.forEach((value, key) => {
          console.log(key + ', ' + value);
        })
        this.coreService.postRequest('api/event/batch_send_email', formData)
        .then((result) => {
          if(result && result.success) {
            alert('Successfully Send Email Notifications');
            this.router.navigate(['/event/list']);
          }
        })
        .catch((error) => {
          console.log(error);
          let data = JSON.parse(error._body);
          this.errors = data.errors;
          if(data.errors && typeof data.errors[0] === 'undefined'){
            this.errorIsObject = true;
          }
          if(!this.errorIsObject){
            alert("error: " + data.errors[0]);  
          }
        });
      }
    }
  
  }
  