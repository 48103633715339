import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { faCaretDown, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { CoreService } from '../core.service';
import { HttpParams } from '@angular/common/http';
import { RxwebValidators } from '@rxweb/reactive-form-validators';


@Component({
  selector: 'app-public-image-upload',
  templateUrl: './public-image-upload.component.html',
  styleUrls: ['./public-image-upload.component.css']
})
export class PublicImageUploadComponent implements OnInit {
  checkUserForm: FormGroup;
  uploadImageForm: FormGroup;
  uploadUserType = (this.route.snapshot.params['type'] as string).toLowerCase();

  studentOnChecked = false;
  isStudent = false;
  userIsValid = false;
  submitted = false;
  checkUserSubmitted = false;
  checkUserFormValid = false;
  recaptchaDone = false;
  uploadImageFormValid = false;
  imageFileSizeValid = false;
  imageDimensionValid = false;
  studentEmail: string;
  studentIdParam: string;
  tokenParam: string;

  imgURL: any;
  imgBase64: any = '';
  user: any;

  fieldToDisable = [
    'email',
    'name',
    'student_id'
  ];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    let fields = {
      captcha: ['', Validators.required],
      email: ['', Validators.required],
      student_id: null
    };
    if(this.uploadUserType === 'student') {
      this.isStudent = true;
      fields.student_id = ['', Validators.required];
    }

    this.checkUserForm = this.formBuilder.group(fields);

    this.route.queryParams
      .subscribe(async params => {
        if(this.isStudent && params.student_id && params.token) {
          this.studentIdParam = params.student_id;
          this.tokenParam = params.token;
          let postParams = {
            student_id: this.studentIdParam,
            token: this.tokenParam
          };
          this.userIsValid = true;
          try {
            let fields = {
              image: ['', [Validators.required, RxwebValidators.extension({extensions: ['png', 'jpg', 'jpeg']})]],
              email: ['', Validators.required],
              name: ['', Validators.required],
              student_id: ['', Validators.required],
              token: [this.tokenParam, Validators.required],
              id: ['', Validators.required]
            };
            this.uploadImageForm = this.formBuilder.group(fields);

            let validateResult = await this.coreService.postRequest('api/validate_token', postParams);
            if(validateResult && validateResult.success) {
              let studentParam = new HttpParams().set('student_id', this.studentIdParam);
              try {
                let getStudentInfoResult = await this.coreService.getRequest('api/student_info?' + studentParam.toString());

                if(getStudentInfoResult && getStudentInfoResult.success && getStudentInfoResult.data && getStudentInfoResult.data.student) {
                  let dataToPatch : any = {
                    image: '',
                    name: getStudentInfoResult.data.student.name,
                    email: getStudentInfoResult.data.student.email,
                    student_id: getStudentInfoResult.data.student.student_id,
                    id: getStudentInfoResult.data.employee.id
                  }

                  this.user = getStudentInfoResult.data.employee;
                  this.uploadImageForm.patchValue(dataToPatch);
          
                  for(let key of this.fieldToDisable) {
                    if(this.uploadImageForm.controls[key]) {
                      this.uploadImageForm.controls[key].disable();
                    }
                  }
                }
              } catch (error) {
                this.userIsValid = false;
                console.log(error);
                if(error._body) {
                  let data = JSON.parse(error._body);
                  let msg = data.errors;
                  if(data.errors && data.errors.length > 0) {
                    msg = data.errors.join('\n');
                  }
                  alert(msg);
                }
              }
            }
          } catch (error) {
            this.userIsValid = false;
            console.log(error);
            if(error._body) {
              let data = JSON.parse(error._body);
              let msg = data.errors;
              if(data.errors && data.errors.length > 0) {
                if(data.errors[0].toLowerCase().includes('invalid token') || data.errors[0].toLowerCase().includes('token expired')) {
                  msg = 'The link you clicked has expired, please click “OK” to re-register.';
                  alert(msg);
                  this.router.navigate(['/upload-image/' + this.uploadUserType]).then(() => window.location.reload());
                } else {
                  msg = data.errors.join('\n');
                  alert(msg);
                }
              }
            }
          }
        }
      }
    );
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    let postParams = {
      recaptchaToken: captchaResponse
    };
    this.recaptchaDone = false;
    if(captchaResponse && captchaResponse !== '') {
      this.checkUserFormValid = true;
      this.coreService.postRequest('api/recaptcha/check', postParams)
      .then((result) => {
        if(result.success) {
          console.log('success')
          this.recaptchaDone = true;
        }
      })
      .catch((error) => {
        this.checkUserForm.controls['captcha'].setValue('');
        this.recaptchaDone = false;
        this.checkUserFormValid = false;
        console.log(error);
      });
    } else {
      this.recaptchaDone = false;
      this.checkUserFormValid = false;
    }
  }

  checkUser() {
    this.checkUserSubmitted = true;
    if (this.checkUserForm.valid) {
      this.checkUserFormValid = true;
      let params = new HttpParams().set('email', this.checkUserForm.value.email);
      if(this.checkUserForm.value.student_id) {
        params = params.set('student_id', this.checkUserForm.value.student_id);
      }
      
      this.coreService.getRequest('api/info?' + params.toString())
        .then((result : any) => {
          if(result && result.success && result.data && result.data.employee) {
            if(this.isStudent) {
              this.studentOnChecked = true;
              this.studentEmail = result.data.employee.student.email;
              return;
            }
            this.user = result.data.employee;

            this.userIsValid = true;

            let fields = {
              id: ['', Validators.required],
              image: ['', [Validators.required, RxwebValidators.extension({extensions: ['png', 'jpg', 'jpeg']})]],
              email: ['', Validators.required],
              name: ['', Validators.required]
            };

            let dataToPatch : any = {
              image: '',
              name: this.user.name,
              email: this.user.email,
              id: this.user.id
            }
        
            this.uploadImageForm = this.formBuilder.group(fields);
            

            this.uploadImageForm.patchValue(dataToPatch);

            for(let key of this.fieldToDisable) {
              if(this.uploadImageForm.controls[key]) {
                this.uploadImageForm.controls[key].disable();
              }
            }
          }
        })
        .catch((error) => {
          this.checkUserFormValid = false;
          console.log(error);
          if(error._body) {
            let data = JSON.parse(error._body);
            let msg = data.errors;

            if(data.errors && data.errors.length > 0) {
              msg = data.errors.join('\n');
              msg = "error: " + msg
            }

            if(data.invalid) {
              msg = data.invalid;
            }

            alert(msg);

          }
        });
    } else {
      this.checkUserFormValid = false;
    }
  }

  updateFileNames(files) {
    let fileNames = Array.from(files).map((file: any) => file.name).join(', ');
    let customFileLabel = document.getElementById('customFileLabel');
    customFileLabel.innerHTML = fileNames || 'Choose Photo';
  }

  onSubmit() {
    this.submitted = true;
    let postParams = this.uploadImageForm.getRawValue();
    
    if (this.uploadImageForm.valid && this.imageFileSizeValid && this.imageDimensionValid) {
      this.uploadImageFormValid = true;
      if (this.imgBase64) {
        postParams.image = this.imgBase64;
      }
      else {
        delete postParams.image;
      }

      this.coreService.putRequest('api/employee/' + this.user.id + '/public-image-upload', postParams)
        .then((result) => {
          if(result.success) {
            if(this.isStudent) {
              alert('Uploaded Successfully');
              this.router.navigate(['/upload-image/student']).then(() => window.location.reload());
            } else {
              alert('Uploaded Successfully');
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          this.uploadImageFormValid = false;
          console.log(error);
          if(error._body) {
            let data = JSON.parse(error._body);
            if(data.errors) {
              let msg = data.errors;
              if(data.errors && data.errors.length > 0) {
                msg = data.errors.join('\n');
              }
              alert(msg);
            } else {
              alert('An unexpected error has occurred, please contact your respective Programme Executives (PEs)')
            }
          }
        });
    }else{
      this.uploadImageFormValid = false;
    }
  }
  getImageDimensions(imgBase64: string) {
    return new Promise (function (resolved, rejected) {
      var i = new Image()
      i.onload = function(){
        resolved({width: i.width, height: i.height})
      };
      i.src = imgBase64
    })
  }

  checkFileSize(file: Blob) {
    if (file && file.size > (5 * 1000 * 1000)) {
      // 3 (M) * 1000 (K) * 1000 (Byte)
      this.imageFileSizeValid = false;
    } else {
      this.imageFileSizeValid = true;
    }
  }

  async checkImageDimension(imgBase64: string) {
    let dimensions: any = await this.getImageDimensions(imgBase64);
    if(dimensions.width >= 300 && dimensions.height >= 491) {
      // width 8cm (302px), height 13cm (491px)
      this.imageDimensionValid = true;
    } else {
      this.imageDimensionValid = false;
    }
  }
  
  preview(event: { target: { files: Blob[]; }; }) {
    this.checkFileSize(event.target.files[0]);
    this.updateFileNames(event.target.files);
    if(this.uploadImageForm.valid && this.imageFileSizeValid && event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = async (event) => { // called once readAsDataURL is completed
        if(reader && reader.result && typeof reader.result === 'string') {
          await this.checkImageDimension(reader.result);
          if(this.imageDimensionValid) {
            this.imgBase64 = reader.result;
            this.imgURL = reader.result;
            this.uploadImageFormValid = true;
          } else {
            this.imgBase64 = '';
            this.imgURL = '';
            this.uploadImageFormValid = false;
          }
        }
      }
    } else {
      this.imgBase64 = '';
      this.imgURL = '';
      this.uploadImageFormValid = false;
    }
  }
}
