import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private coreService: CoreService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var user_data = JSON.parse(localStorage.getItem('user_login_data'));
    if ((!user_data || !user_data.token)
      || (!user_data.role || user_data.role.length <= 0)) {
      // this.router.navigate(['/401-unauthorised']);
      // let urlPath = route.url.split('/');
      // console.log(route.url[0].path);
      console.log('no login session');
      this.router.navigate(['/login']);
      return false;
    }

    // console.log(user_data);
    // let roleAccessible = false;
    // for (let i = 0; i < user_data.role.length; i++) {
    // 	if (route.data.roles.indexOf(user_data.role[i].name) !== -1) {
    // 		roleAccessible = true;
    // 		break;
    // 	}
    // }

    // if (!roleAccessible) {
    // 	this.router.navigate(['/401-unauthorised']);
    // 	return false;
    // }

    let userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));

    // console.log(route.data.permission);
    if (typeof route.data.permission != 'undefined') {
      let permissionFlag = false;
      // for (let i = 0; i < userRolePerm.role_permission.permissions.length; i++) {
      // for (let i = 0; i < userRolePerm.length; i++) {
      //   // console.log(userRolePerm.role_permission.permissions[i].permission_name)
      //   // console.log(route.data.permission);
      //   // if (route.data.permission == userRolePerm.role_permission.permissions[i].permission_name) {
      //   if (route.data.permission == userRolePerm[i].permission_name) {
      //     permissionFlag = true;
      //   }
      // }

      const userRolePermNames = userRolePerm.map(uRoleP => uRoleP.permission_name);

      if (userRolePermNames.includes(route.data.permission)) {
        permissionFlag = true;
      }

      if (!permissionFlag) {
        // alert('Error 401. Unauthorised. Return to home page.')

        // find all routes, redirect to the first found list that has permission
        for (const routeConfigs of this.router.config) {
          if (routeConfigs.path.endsWith('/list')) {
            if (userRolePermNames.includes(routeConfigs.data.permission)) {
              this.router.navigate([`/${routeConfigs.path}`]);
              return true;
            }
          }
        }

        // if the user do not has any view permission, redirect to login
        this.router.navigate(['/login']);
        // this.router.navigate(['/']); // redirect to 404
        return false;
      }
    }

    return true;
  }

}
