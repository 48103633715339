import { ChangeDetectionStrategy, Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CoreService } from '../core.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { VisitorParameterService } from './visitor-parameter.service';
import DownloadHelper from '../download-helper';

@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.css']
})
export class VisitorComponent implements OnInit {
  @ViewChild('modal_content', { static: false })
  private modelContent!: TemplateRef<any>;
  modal_body: string = '';
  modal_title: string = '';
  modalDetails: any = {
    modal_title: '',
    modal_body: '',
    modal_type: ''
  };

  userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
  advancedSearchForm: FormGroup;
  delete_id = -1;
  onDeleteType = false;
  onResetType = false;
  reset_id = -1;
  tableRecords = [];
  tableParams = new HttpParams();
  // tableTotalRecords = 0;
  // tableCurPage = 1;
  // tablePageSize = 20;
  sortby = '';
  DESC = false;
  studentData: [];
  searchName = '';
  // searchTerm = '';
  startdate = moment().format('YYYY-MM-DD');
  enddate = moment().add(1, 'day').format('YYYY-MM-DD');
  hoveredDate: NgbDate | null = null;
  // fromDate: NgbDate | null;
  // toDate: NgbDate | null;
  export = false;

  timeOptions = [
    "00:00:00",
    "00:30:00",
    "01:00:00",
    "01:30:00",
    "02:00:00",
    "02:30:00",
    "03:00:00",
    "03:30:00",
    "04:00:00",
    "04:30:00",
    "05:00:00",
    "05:30:00",
    "06:00:00",
    "06:30:00",
    "07:00:00",
    "07:30:00",
    "08:00:00",
    "08:30:00",
    "09:00:00",
    "09:30:00",
    "10:00:00",
    "10:30:00",
    "11:00:00",
    "11:30:00",
    "12:00:00",
    "12:30:00",
    "13:00:00",
    "13:30:00",
    "14:00:00",
    "14:30:00",
    "15:00:00",
    "15:30:00",
    "16:00:00",
    "16:30:00",
    "17:00:00",
    "17:30:00",
    "18:00:00",
    "18:30:00",
    "19:00:00",
    "19:30:00",
    "20:00:00",
    "20:30:00",
    "21:00:00",
    "21:30:00",
    "22:00:00",
    "22:30:00",
    "23:00:00",
    "23:30:00",
  ];

  // startTime = '00:00:00';
  // endTime = '00:00:00';
  all_status = [{
    id: 'approved',
    name: "Approved"
  }, {
    id: 'pending',
    name: "Pending"
  }, {
    id: 'rejected',
    name: "Rejected"
  }];
  // status = "";

  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private coreService: CoreService,
    private sanitizer: DomSanitizer,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private visitorParameterService: VisitorParameterService
  ) {
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
  }

  ngOnInit() {
    this.advancedSearchForm = this.formBuilder.group({
      searchTerm: [''],
      status: ['']
    });
    this.resetAndRefreshTable();
  }

  get searchTerm(): string {
    return this.visitorParameterService.searchTerm;
  }

  set searchTerm(value: string) {
    this.visitorParameterService.searchTerm = value;
  }

  get tableCurPage(): number {
    return this.visitorParameterService.page;
  }

  set tableCurPage(value: number) {
    this.visitorParameterService.page = value;
  }

  get tablePageSize(): number {
    return this.visitorParameterService.perPage;
  }

  set tablePageSize(value: number) {
    this.visitorParameterService.perPage = value;
  }

  get tableTotalRecords(): number {
    return this.visitorParameterService.totalRecords;
  }

  set tableTotalRecords(value: number) {
    this.visitorParameterService.totalRecords = value;
  }

  get status(): string {
    return this.visitorParameterService.status;
  }

  set status(value: string) {
    this.visitorParameterService.status = value;
  }

  get fromDate(): any {
    return this.visitorParameterService.startDate;
  }

  set fromDate(value: any) {
    this.visitorParameterService.startDate = value;
  }

  get startTime(): string {
    return this.visitorParameterService.startTime;
  }

  set startTime(value: string) {
    this.visitorParameterService.startTime = value;
  }

  get toDate(): any {
    return this.visitorParameterService.endDate;
  }

  set toDate(value: any) {
    this.visitorParameterService.endDate = value;
  }

  get endTime(): string {
    return this.visitorParameterService.endTime;
  }

  set endTime(value: string) {
    this.visitorParameterService.endTime = value;
  }

  resetAndRefreshTable(action = '') {
    // if (this.searchTerm) {
    //   this.tableParams = this.tableParams.set('term', this.searchTerm);
    // }
    // else {
    //   if (this.tableParams.has('term')) {
    //     this.tableParams = this.tableParams.delete('term');
    //   }
    // }
    if (action == 'modal_call_to_action') {
      this.tableCurPage = 1;
    }
    this.tableParams = this.tableParams.set('page', this.tableCurPage.toString());
    this.tableParams = this.tableParams.set('per_page', this.tablePageSize.toString());
    this.getTableData(this.tableParams);
  }

  getTableData(params: HttpParams) {
    if (this.searchTerm != '') {
      params = params.set('term', this.searchTerm);
    }
    if (this.status != '') {
      params = params.set('status', this.status);
    }
    if (this.fromDate && this.toDate) {
      params = params.set('start_date', moment(this.formatter.format(this.fromDate) + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss'));
      params = params.set('end_date', moment(this.formatter.format(this.toDate) + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss'));
    }
    if (this.site_id) {
      params = params.set('site_id', this.site_id);
    }

    var url = 'api/visit_request?' + params.toString();

    if (this.export == true) {
      params = params.set('export', 'true');
      this.coreService.downloadRequest(url + params.toString())
        .then(data => {
          // let options = { type: 'text/xlsx;charset=utf-8;' };
          // application/vnd.ms-excel
          let options = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
          var contentDisposition = data.headers.get('Content-Disposition');
          var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          DownloadHelper.createAndDownloadBlobFile(data._body, options, filename);
        }, error => {
          let data = JSON.parse(error._body);
          alert("error: " + data.message);
        });
    } else {
      this.coreService.getRequest(url)
      .then(data => {
        for (var i = 0; i < data.data.visit_requests.data.length; i++) {
          data.data.visit_requests.data[i]['startdate_formatted'] = moment(data.data.visit_requests.data[i].start_datetime).format('DD/MM/YYYY');
          data.data.visit_requests.data[i]['starttime_formatted'] = moment(data.data.visit_requests.data[i].start_datetime).format('HH : mm : ss');
          data.data.visit_requests.data[i]['enddate_formatted'] = moment(data.data.visit_requests.data[i].end_datetime).format('DD/MM/YYYY');
          data.data.visit_requests.data[i]['endtime_formatted'] = moment(data.data.visit_requests.data[i].end_datetime).format('HH : mm : ss');
        }
        this.tableRecords = data.data.visit_requests.data;
        this.tableTotalRecords = data.data.visit_requests.total;
      }, error => {
        console.log(error);
      });
    }
    this.export = false;
  }

  onDelete(id) {
    this.onDeleteType = true;
    this.onResetType = false;
    this.delete_id = id;
    this.modalDetails.modal_title = 'Alert';
    this.modalDetails.modal_body = "Are you sure you want to delete this student ?";
    this.modalDetails.modal_type = "modal_call_to_action";
    this.modalService.open(this.modelContent, { centered: true });
  }

  modalCallToAction() {
    if (this.modalDetails.modal_type == 'modal_call_to_action') {
      if (this.onDeleteType == true) {
        this.DeleteCallToAction();
      }
    }
  }

  DeleteCallToAction() {
    this.modalService.dismissAll();
    if (this.modalDetails.modal_type == 'modal_call_to_action') {
      this.coreService.deleteRequest('api/student/' + this.delete_id)
        .then(data => {
          if (data.success) {
            alert('Successfully Deleted Student');
            this.resetAndRefreshTable();
            location.reload();
          }
        }, error => {
          console.log(error);
          alert('Delete Student Fail');
        })
    }
  }

  onAdvancedSearch() {
    let postParams = this.advancedSearchForm.value
    this.searchTerm = postParams.searchTerm;
    this.status = postParams.status;
    this.resetAndRefreshTable();
  }

  sorting(sortby) {
    this.sortby = sortby;
    if (this.DESC == true) {
      this.DESC = false;
    } else {
      this.DESC = true;
    }
    this.resetAndRefreshTable();
  }

  checkPermissionName(perm_name) {
    let userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
    let data = userRolePerm;

    for (var i = 0; i < data.length; i++) {
      if (perm_name == data[i].permission_name) {
        return true;
      }
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || this.fromDate == date)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  exportReport() {
    this.export = true;
    this.getTableData(this.tableParams);
  }
}
