import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {

  addEventForm: FormGroup;
  submitted = false;
  message: string;

  currentDate: Date;
  timeOptions: Array<string>= [];
  timeMinutes: Array<string> = [
    "00",
    "30"
  ];
  
  subscriber_id = localStorage.getItem('curSubscriber');
  sites: any = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.currentDate = new Date(moment().startOf('day').valueOf());
  }

  ngOnInit() {
    this.addEventForm = this.formBuilder.group({
      site_id: ['', Validators.required],
      name: ['', Validators.required],
      description: '',
      limit: ['', Validators.required],
      date: ['', Validators.required],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      subscriber_id: ['', Validators.required]
    });

    this.getSites(this.subscriber_id);
    this.timeOptions = this.generateTimeOptions(this.timeMinutes);

    this.addEventForm.patchValue({
      subscriber_id: this.subscriber_id,
      date: this.currentDate,
      start_time: '00:00:00',
      end_time: '00:30:00'
    });
  }

  generateTimeOptions(minutesArray: Array<string>): Array<string> {
    let retTimeOptions: Array<string> = [];
    for(let i: number = 0; i < 24; i++) {
      //hour

      let hourText: string = '';
      if(i < 10) {
        hourText += '0' + String(i);
      } else {
        hourText = String(i);
      }

      let secondText: string = '00';
      for(let j: number = 0; j < minutesArray.length; j++) {
        //minute
        
        let minuteText: string = this.timeMinutes[j];
        retTimeOptions.push(`${hourText}:${minuteText}:${secondText}`);
      }
    }

    return retTimeOptions;
  }

  getSites(subscriber_id: string) {
    this.coreService.getRequest('api/site?subscriber_id='+subscriber_id)
    .then((data) => {
      if (data && data.success) {
        this.sites = data.data.sites.data;
      }
    })
    .catch((error) => {
      console.log(error);
      if(error._body) {
        let data = JSON.parse(error._body);
        let msg = data.errors;
        if(data.errors && data.errors.length > 0) {
          msg = data.errors.join('\n');
        }
        alert("error: " + msg);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    let postParams = JSON.parse(JSON.stringify(this.addEventForm.value));
    postParams.date = moment(postParams.date).format('YYYY-MM-DD');

    // stop here if form is invalid
    if (this.addEventForm.valid) {
      this.coreService.postRequest('api/event', postParams)
      .then((data) => {
        alert('Successfully Added Event');
        this.router.navigate(['/event/list']);
      })
      .catch((error) => {
        console.log(error);
        if(error._body) {
          let data = JSON.parse(error._body);
          let msg = data.errors;
          if(data.errors && data.errors.length > 0) {
            msg = data.errors.join('\n');
          }
          alert("error: " + msg);
        }
      });
    }
  }

  onReset() {
    this.submitted = false;
    this.addEventForm.reset();
  }
}
