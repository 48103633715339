import * as moment from 'moment';

import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { CoreService } from '../../core.service';
import { VisitorParameterService } from '../visitor-parameter.service';

@Component({
  selector: 'app-visitor-info',
  templateUrl: './visitor-info.component.html',
  styleUrls: ['./visitor-info.component.css']
})
export class VisitorInfoComponent implements OnInit {
  visitorInfoForm: FormGroup;
  visitRequest: any = [];
  genders = [{ 
    type: 'M',
    name: "Male" 
  }, { 
    type: 'F',
    name: "Female" 
  }];
  purposes: any = [];
  isEvent = false;
  editMode = false;
  disabledField = false;
  submitted = false;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  startDate = new Date(moment().startOf('day').valueOf());
  endDate = new Date(moment().startOf('day').add(1, 'd').valueOf());

  timeOptions = [
    "00:00:00",
    "00:30:00",
    "01:00:00",
    "01:30:00",
    "02:00:00",
    "02:30:00",
    "03:00:00",
    "03:30:00",
    "04:00:00",
    "04:30:00",
    "05:00:00",
    "05:30:00",
    "06:00:00",
    "06:30:00",
    "07:00:00",
    "07:30:00",
    "08:00:00",
    "08:30:00",
    "09:00:00",
    "09:30:00",
    "10:00:00",
    "10:30:00",
    "11:00:00",
    "11:30:00",
    "12:00:00",
    "12:30:00",
    "13:00:00",
    "13:30:00",
    "14:00:00",
    "14:30:00",
    "15:00:00",
    "15:30:00",
    "16:00:00",
    "16:30:00",
    "17:00:00",
    "17:30:00",
    "18:00:00",
    "18:30:00",
    "19:00:00",
    "19:30:00",
    "20:00:00",
    "20:30:00",
    "21:00:00",
    "21:30:00",
    "22:00:00",
    "22:30:00",
    "23:00:00",
    "23:30:00"
  ];

  startTime = moment().format("HH") + ':00:00';
  endTime = moment().add(1, 'hour').format("HH") + ':00:00';

  visitor_types: any = [];
  availableEvents: any = [];

  sites = JSON.parse(localStorage.getItem('sites'));
  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private visitorParameterService: VisitorParameterService
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    // this.toDate = calendar.getToday();
  }

  ngOnInit() {
    // this.startdate = moment(this.formatter.format(this.fromDate) + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss');
    // this.enddate = moment(this.formatter.format(this.toDate) + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss');

    this.visitorInfoForm = this.formBuilder.group({
      name: ["", Validators.required],
      sex: ["", Validators.required],
      email: ["", Validators.required],
      mobile: "",
      type: ["", Validators.required],
      purpose: ["", Validators.required],
      start_datetime: ["", Validators.required],
      end_datetime: ["", Validators.required],
      site_id: ["", Validators.required],
      is_single_day_use: "",
      remarks: "",
      status: ["", Validators.required],
      approved_by: "",
      approved_at: "",
      access_all_sites: "",
    });

    this.getVisitorTypes();
    this.getAvailableEvents();
    this.getVisitPurposes();

    if(this.route.snapshot.params['id']){
      this.editMode = true;
      let controlsDisableToggle = [
        'name',
        'sex',
        'email',
        'mobile',
        'type',
        'purpose',
        'eventName',
        'site_id',
        'is_single_day_use',
        'remarks',
        'approved_by',
        'approved_at',
        'start_datetime',
        'end_datetime'
      ];

      this.coreService.getRequest('api/visit_request/' + this.route.snapshot.params['id'])
      .then((data) => {
        if (data && data.success) {
          this.startDate = new Date(moment(data.data.visit_request.start_datetime).startOf('day').valueOf());
          this.startTime = moment(data.data.visit_request.start_datetime).format('HH:mm:ss');
          this.endDate = new Date(moment(data.data.visit_request.end_datetime).startOf('day').valueOf());
          this.endTime = moment(data.data.visit_request.end_datetime).format('HH:mm:ss');
          if(data.data.visit_request.approved_by) {
            data.data.visit_request.approved_by = data.data.visit_request.approved_by.name;
          }
          data.data.visit_request.safe_qr_code = this.sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64,"+data.data.visit_request.qr_code);
          this.visitRequest = data.data.visit_request;

          if(this.visitRequest.status != 'pending'){
            this.editMode = false;
            this.disabledField = true;
          }

          if(this.visitRequest.event) {
            this.isEvent = true;
            if(this.editMode) {
              this.visitorInfoForm.addControl('event_id', new FormControl('', Validators.required));
            } else {
              this.visitRequest.eventName = this.visitRequest.event.name;
              this.visitorInfoForm.addControl('eventName', new FormControl(''));
            }
          }

          if(!this.editMode) {
            controlsDisableToggle.forEach(e => {
              if(this.visitorInfoForm.controls[e]) {
                this.visitorInfoForm.controls[e].disable();
              }
            });
          }

        }
        let patchValue = this.visitRequest;

        if(!this.editMode) {
          if('is_single_day_use' in patchValue) {
            if(patchValue.is_single_day_use) {
              patchValue.is_single_day_use = 'YES'
            } else {
              patchValue.is_single_day_use = 'NO'
            }
          }
        }

        this.visitorInfoForm.patchValue(patchValue);
      },
      (error) => {
        console.log(error);
        let data = JSON.parse(error._body);
      });
    }
  }
  get f() { return this.visitorInfoForm.controls; }

  getVisitorTypes() {
    var url = 'api/visitor_type?subscriber_id='+this.subscriber_id;
    this.coreService.getRequest(url)
    .then(data => {
      if(data && data.success) {
        this.visitor_types = data.data.departments;  
      }
    }, error => {
        console.log(error);
    });
  }

  getAvailableEvents() {
    let params = new HttpParams;
    if(this.subscriber_id){
      params = params.set('subscriber_id', this.subscriber_id);
      if(this.site_id){
        params = params.set('site_id', this.site_id);
      }
    }
    let url = 'api/public_event?' + params.toString();
    this.coreService.getRequest(url)
    .then(result => {
      if(result && result.success) {
        this.availableEvents = result.data.events;
      }
    }, error => {
        console.log(error);
    });
  }

  getVisitPurposes() {
    let params = new HttpParams;
    if(this.subscriber_id){
      params = params.set('subscriber_id', this.subscriber_id);
    }
    let url = 'api/visit_purpose?' + params.toString();
    this.coreService.getRequest(url)
    .then(result => {
      if(result && result.success) {
        this.purposes = result.data.visit_purposes.data;
      }
    }, error => {
        console.log(error);
    });
  }

  onSubmit(status) {
    this.submitted = true;
    this.visitorInfoForm.patchValue({
      start_datetime:  moment(moment(this.startDate).format('YYYY-MM-DD') + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss'),
      end_datetime:  moment(moment(this.endDate).format('YYYY-MM-DD') + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss'),
      status: status
    });
    let postParams = this.visitorInfoForm.value;
    if(status == 'disabled'){
      postParams.site_id = this.visitRequest.site_id;
    }
    if (this.visitorInfoForm.valid) {
      this.coreService.putRequest('api/visit_request/' + this.route.snapshot.params['id'], postParams)
      .then((data) => {
        alert('Status of the Visit Request Updated');
        this.visitorParameterService.resetParameterService();
        this.router.navigate(['/visitor/list']);
      },
      (error) => {
        console.log(error);
        let data = JSON.parse(error._body);
        alert("error: " + data.errors[0]);
      })
    }
  }

  

}
