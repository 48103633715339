// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiEndpoint: 'http://localhost:4200/',
  // apiEndpoint: 'http://localhost:8000/',
  // apiEndpoint: 'https://sys.gen-net.com.sg/gsms/public/',
  // apiEndpoint: 'http://sunray.gen-net.com.sg/backend/public/',
  // apiEndpoint: 'http://fabristeel.gen-net.com.sg/backend/public/',
  // apiEndpoint: 'https://psb-uat.gen-net.com.sg/backend/',
  apiEndpoint: 'https://smartgantry.psb-academy.edu.sg/backend/',
  // recaptchaSiteKey: '6Lfsc94ZAAAAAADDhOPeHklRajSm6drDd4roVQzZ', // local
  // recaptchaSiteKey: '6Lcewt4ZAAAAAPXoWM_gPn6XTr7vBml7FwZI0NIj', // uat
  recaptchaSiteKey: '6Lccw94ZAAAAAKO9nABrmWJobG8Mk9WWbJt9UGmx', // prd
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
