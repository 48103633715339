import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-public-visitor-registration',
  templateUrl: './public-visitor-registration.component.html',
  styleUrls: ['./public-visitor-registration.component.css']
})
export class PublicVisitorRegistrationComponent implements OnInit {
  visitorRegistrationForm: FormGroup;
  submitted = false;
  genders = [{ 
    type: 'M',
    name: "Male" 
  }, { 
    type: 'F',
    name: "Female" 
  }];
  purposes: any = [];

  startdate: Date;
  enddate: Date;

  timeOptions = [
    "00:00:00",
    "00:30:00",
    "01:00:00",
    "01:30:00",
    "02:00:00",
    "02:30:00",
    "03:00:00",
    "03:30:00",
    "04:00:00",
    "04:30:00",
    "05:00:00",
    "05:30:00",
    "06:00:00",
    "06:30:00",
    "07:00:00",
    "07:30:00",
    "08:00:00",
    "08:30:00",
    "09:00:00",
    "09:30:00",
    "10:00:00",
    "10:30:00",
    "11:00:00",
    "11:30:00",
    "12:00:00",
    "12:30:00",
    "13:00:00",
    "13:30:00",
    "14:00:00",
    "14:30:00",
    "15:00:00",
    "15:30:00",
    "16:00:00",
    "16:30:00",
    "17:00:00",
    "17:30:00",
    "18:00:00",
    "18:30:00",
    "19:00:00",
    "19:30:00",
    "20:00:00",
    "20:30:00",
    "21:00:00",
    "21:30:00",
    "22:00:00",
    "22:30:00",
    "23:00:00",
    "23:30:00"
  ];

  startTime = moment().format("HH")+':00:00';
  endTime = moment().add(1, 'hour').format("HH")+':00:00';

  timeValidArr: any = [];
  visitor_types: any = [];

  sites = [];
  subscriber_id = 1;
  event_id = this.route.snapshot.queryParams['eventId'];
  student_id = this.route.snapshot.queryParams['studentId'];
  isEvent: boolean = false;
  eventDetail: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    this.startdate = new Date(moment().startOf('day').valueOf());
    this.enddate = new Date(moment().startOf('day').valueOf());
  }

  ngOnInit() {
    // this.startdate = moment(this.formatter.format(this.fromDate) + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss');
    // this.enddate = moment(this.formatter.format(this.toDate)  + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss');
    let fields: any = {
      subscriber_id: new FormControl(this.subscriber_id),
      name: new FormControl('', Validators.required),
      sex: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      mobile: new FormControl(''),
      remarks: new FormControl(''),
      visit_datetime: new FormArray([])
    };

    this.getVisitPurposes();
    this.getVisitorTypes();
    this.getSites();
    
    if(this.event_id && this.student_id) {
      this.isEvent = true;
      this.purposes = ['Event'];
      fields.type = new FormControl({value: '', disabled: this.isEvent});
      fields.purpose = new FormControl({value: '', disabled: this.isEvent});
      fields.eventName = new FormControl({value: '', disabled: this.isEvent});
      fields.site_name = new FormControl({value: '', disabled: this.isEvent});
      fields.site_id = new FormControl('');
      this.getEvent();
    } else {
      this.isEvent = false;
      fields.type = new FormControl('', Validators.required);
      fields.purpose = new FormControl('', Validators.required);
      fields.site_id = new FormControl('', Validators.required);

    }

    this.visitorRegistrationForm = new FormGroup(fields);
    console.log(this.visitorRegistrationForm);
  }
  get f() { return this.visitorRegistrationForm.controls; }

  get fa(): FormArray {
    return this.visitorRegistrationForm.get('visit_datetime') as FormArray;
  }

  addCreds(disabled: boolean) {
    const creds = this.visitorRegistrationForm.controls.visit_datetime as FormArray;
    creds.push(this.formBuilder.group({
      start_date: new FormControl({value: '', disabled: disabled}, Validators.required),
      end_date: new FormControl({value: '', disabled: disabled}, Validators.required),
      start_time: new FormControl({value: '', disabled: disabled}, Validators.required),
      end_time: new FormControl({value: '', disabled: disabled}, Validators.required)
    }));
    this.timeValidArr.push(true);
  }

  removeCreds(index: number) {
    (this.visitorRegistrationForm.controls.visit_datetime as FormArray).removeAt(index);
    this.timeValidArr.splice(index, 1);
  }

  getVisitPurposes() {
    var url = 'api/visit_purpose';
    if(this.subscriber_id){
      url += '?subscriber_id='+this.subscriber_id;
    }
    this.coreService.getRequest(url)
    .then(result => {
      if(result && result.success) {
        this.purposes = result.data.visit_purposes.data;
      }
    }, error => {
        console.log(error);
    });
  }

  getVisitorTypes() {
    var url = 'api/visitor_type?subscriber_id='+this.subscriber_id;
    this.coreService.getRequest(url)
    .then(result => {
      if(result && result.success) {
        this.visitor_types = result.data.departments;  
      }
    }, error => {
        console.log(error);
    });
  }

  getSites() {
    var url = 'api/site?status=active&subscriber_id='+this.subscriber_id;
    this.coreService.getRequest(url)
    .then(data => {
      if(data && data.success) {
        this.sites = data.data.sites.data;  
      }
    }, error => {
        console.log(error);
    });
  }

  getEvent() {
    var url = 'api/public_event/'+this.event_id;
    this.coreService.getRequest(url)
    .then(result => {
      console.log(result)
      if(result && result.success) {
        let type = this.visitor_types.find(type => type.name.toLowerCase() === 'visitor');
        let site = this.sites.find(site => String(site.id) === String(result.data.event.site_id));
        this.addCreds(true);
        let fieldToPatch = {
          eventName: result.data.event.name,
          type: String(type.id),
          purpose: 'Event',
          site_id: String(result.data.event.site_id),
          site_name: site.site_name,
        };
        
        let dateTimeToPatch = {
          start_date: result.data.event.date,
          end_date: result.data.event.date,
          start_time: result.data.event.start_time,
          end_time: result.data.event.end_time
        };

        this.visitorRegistrationForm.patchValue(fieldToPatch);
        ((this.visitorRegistrationForm.controls.visit_datetime as FormArray).controls[0] as FormGroup).patchValue(dateTimeToPatch);

        this.eventDetail = {
          type: fieldToPatch.type,
          event_id: String(this.event_id),
          site_id: fieldToPatch.site_id,
          purpose: fieldToPatch.purpose,
          visit_datetime: [dateTimeToPatch]
        }
      }
    })
    .catch(error => {
        console.log(error);
    });
  }

  parseDateToString(dateObj: moment.MomentInput) {
    return moment(dateObj).format('YYYY-MM-DD');
  }

  onSubmit() {
    // this.startdate = moment(this.formatter.format(this.fromDate) + ' ' + this.startTime).format('YYYY-MM-DD HH:mm:ss');
    // this.enddate = moment(this.formatter.format(this.toDate)  + ' ' + this.endTime).format('YYYY-MM-DD HH:mm:ss');

    this.submitted = true;
    if(this.visitorRegistrationForm.controls.visit_datetime['controls'].length == 0){
      return;
    }
    
    let postParams = JSON.parse(JSON.stringify(this.visitorRegistrationForm.value));
    postParams.visit_datetime = postParams.visit_datetime.map((datetime: { start_date: moment.MomentInput; end_date: moment.MomentInput; }) => {
      datetime.start_date = this.parseDateToString(datetime.start_date);
      datetime.end_date = this.parseDateToString(datetime.end_date);
      return datetime;
    });

    if(this.isEvent) {
      Object.assign(postParams, this.eventDetail);
    }

    if (this.visitorRegistrationForm.valid) {

      this.coreService.postRequest('api/visit_request/public', postParams)
      .then((data) => {
        if(data.success) {
          alert('Visitor Registration Created Successfully!');
          if(this.isEvent) {
            this.router.navigate(['visitor/public-registration']).then(() => window.location.reload());
          } else {
            window.location.reload();
          }
        } else if(
          !data.success
          && !data.errors
          && data.data
          && data.data.invalid_visit_requests
          && data.data.invalid_visit_requests.length > 0
          && data.data.timeValidArr
          && data.data.timeValidArr.length > 0
        ) {
          let msg = 'The following date and time is added:\n';

          for(let invalidVR of data.data.invalid_visit_requests) {
            msg += `\nTime: ${invalidVR.start_datetime} - ${invalidVR.end_datetime}`;
          }

          this.timeValidArr = data.data.timeValidArr;

          alert(msg);
        }
      },
      (error) => {
        console.log(error);
        if(error._body) {
          let data = JSON.parse(error._body);
          if(data.errors && data.errors.length && data.errors.length > 0) {
            alert(data.errors.join('\n'));
          }
        }
      })
    }
  }
}
