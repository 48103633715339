import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_BASE_URL } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from './layout/layout.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CoreService } from './core.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { VisitorReportComponent } from './report/visitor-report/visitor-report.component';
import { VisitorComponent } from './visitor/visitor.component';
import { ApproveVisitorComponent } from './visitor/approve-visitor/approve-visitor.component';
import { VisitorInfoComponent } from './visitor/visitor-info/visitor-info.component';
import { VisitorPendingComponent } from './visitor/visitor-pending/visitor-pending.component';
import { VisitorRegistrationComponent } from './visitor/visitor-registration/visitor-registration.component';
import { StudentComponent } from './student/student.component';
import { AddStudentComponent } from './student/add-student/add-student.component';
import { EditStudentComponent } from './student/edit-student/edit-student.component';
import { StudentAttendanceComponent } from './student/student-attendance/student-attendance.component';
import { LessonComponent } from './lesson/lesson.component';
import { AddLessonComponent } from './lesson/add-lesson/add-lesson.component';
import { EditLessonComponent } from './lesson/edit-lesson/edit-lesson.component';
import { BatchUploadStudentComponent } from './student/batch-upload-student/batch-upload-student.component';
import { BatchUploadEmployeeComponent } from './employee/batch-upload-employee/batch-upload-employee.component';
import { PublicImageUploadComponent } from './public-image-upload/public-image-upload.component';
import { PublicVisitorRegistrationComponent } from './visitor/public-visitor-registration/public-visitor-registration.component';
import { EventComponent } from './event/event.component';
import { AddEventComponent } from './event/add-event/add-event.component';
import { EditEventComponent } from './event/edit-event/edit-event.component';
import { BatchSendEmailEventComponent } from './event/batch-send-email-event/batch-send-email-event.component';
import { EmployeeComponent } from './employee/employee.component';
import { AddEmployeeComponent } from './employee/add-employee/add-employee.component';
import { EditEmployeeComponent } from './employee/edit-employee/edit-employee.component';
import { ChangePasswordComponent } from './password/change-password/change-password.component';
import { StudentPublicPhotoUploadComponent } from './student-public-photo-upload/student-public-photo-upload.component';
import { BatchUploadVisitorComponent } from './visitor/batch-upload-visitor/batch-upload-visitor.component';
import { StudentAttendanceReportComponent } from './report/student-attendance-report/student-attendance-report.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    VisitorReportComponent,
    VisitorComponent,
    ApproveVisitorComponent,
    VisitorInfoComponent,
    VisitorPendingComponent,
    VisitorRegistrationComponent,
    StudentComponent,
    AddStudentComponent,
    EditStudentComponent,
    StudentAttendanceComponent,
    LessonComponent,
    AddLessonComponent,
    EditLessonComponent,
    BatchUploadStudentComponent,
    BatchUploadEmployeeComponent,
    PublicImageUploadComponent,
    PublicVisitorRegistrationComponent,
    EventComponent,
    AddEventComponent,
    EditEventComponent,
    BatchSendEmailEventComponent,
    EmployeeComponent,
    AddEmployeeComponent,
    EditEmployeeComponent,
    ChangePasswordComponent,
    StudentPublicPhotoUploadComponent,
    BatchUploadVisitorComponent,
    StudentAttendanceReportComponent
  ],
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    LayoutModule,
    FontAwesomeModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    NgSelectModule
    // NgOptionHighlightModule
  ],
  providers: [CoreService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptchaSiteKey } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://www.recaptcha.net/recaptcha/api.js', // use recaptcha.net script source for some of our users
    },
    {
      provide: OWL_DATE_TIME_LOCALE,
      useValue: 'sg'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
