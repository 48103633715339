import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { faCaretDown, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { CoreService } from '../../core.service';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    faCaretDown = faCaretDown;
    faCalendarAlt = faCalendarAlt;

    pageTitle = '';
    pageSubTitle = '';
    pageTabs = [
      // {
      //   title: 'Dashboard',
      //   iconClass: 'ti-dashboard',
      //   link: '/dashboard',
      //   type: 'tab'
      // },

      // {
      //   title: 'Employees',
      //   iconClass: 'fas fa-users',
      //   link: '/employee',
      //   type: 'dropdown',
      //   children: [{
      //     title: 'List',
      //     iconClass: 'fas fa-user-graduate',
      //     link: '/employee/list',
      //   }],
      //   permission: ['view-employee'],
      // },

      {
        title: 'Employees',
        iconClass: 'fas fa-users',
        link: '/employee/list',
        type: 'tab',
        permission: ['view-employee'],
      },


      // {
      //   title: 'Students',
      //   iconClass: 'fas fa-graduation-cap',
      //   link: '/student',
      //   type: 'dropdown',
      //   children: [{
      //     title: 'List',
      //     iconClass: 'fas fa-user-graduate',
      //     link: '/student/list',
      //   }
      //   // ,{
      //   //   title: 'Attendances',
      //   //   iconClass: 'fas fa-user-clock',
      //   //   link: '/student/attendance',
      //   // },{
      //   //   title: 'Programmes',
      //   //   iconClass: 'fa fa-list-alt',
      //   //   link: '/student/lesson',
      //   // }
      //   ],
      //   permission: ['view-student']
      // },

      {
        title: 'Students',
        iconClass: 'fas fa-graduation-cap',
        link: '/student/list',
        type: 'tab',
        permission: ['view-student']
      },

      // {
      //   title: 'Visitors',
      //   iconClass: 'fas fa-user-friends',
      //   link: '/visitor',
      //   type: 'dropdown',
      //   children: [{
      //     title: 'List',
      //     iconClass: 'fas fa-id-badge',
      //     link: '/visitor/list',
      //   }, {
      //     title: 'Registration',
      //     iconClass: 'fas fa-file-alt',
      //     link: '/visitor/registration'
      //   }],
      //   permission: ['view-visit-request']
      // },

      {
        title: 'Visitors',
        iconClass: 'fas fa-user-friends',
        link: '/visitor/list',
        type: 'tab',
        permission: ['view-visit-request']
      },
      // {
      //   title: 'Events',
      //   iconClass: 'fas fa-calendar',
      //   link: '/event',
      //   type: 'dropdown',
      //   children: [{
      //     title: 'List',
      //     iconClass: 'fas fa-list-ol',
      //     link: '/event/list',
      //   }]
      // },
      {
      title: 'Reports',
      iconClass: 'ti-pie-chart',
      link: '/report',
      type: 'dropdown',
      children: [
        {
          title: 'Student Attendance Report',
          iconClass: 'fas fa-calendar-check',
          link: '/report/student-attendance',
        }
      ],
      permission: ['view-report-schedule']
    }
    ];
    curLink = '';
    childLink = '';

    userDetail = JSON.parse(localStorage.getItem('user_login_data'));
    theUserRolePermn = '';
    subscribers: any = [];
    sites: any = [];
    selectedSubscriber: any = '';
    selectedSite: any = '';

    constructor(public router: Router,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private coreService: CoreService
    ) {

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {

              let urlArr = event.url.split('/');
              this.curLink = '/'+urlArr[1];

              if(urlArr.length > 2){
                this.childLink = '/'+urlArr[1]+'/'+urlArr[2];
              }

              this.route.data.subscribe((data) => {
                  // console.log('ActivatedRoute:subscribe', data);
                  if (data['pageTitle']) {
                      this.pageTitle = data['pageTitle'];
                  }
                  if (data['pageSubTitle']) {
                      this.pageSubTitle = data['pageSubTitle'];
                  }
                  // if (data['pageTabs']) {
                  //   this.pageTabs = data['pageTabs'];
                  // }
              });
            }
        });
    }

    ngOnInit() {
        // this.getRolePermission(this.userDetail.role[0].id);
        if(localStorage.getItem('subscribers') && localStorage.getItem('sites')){
          this.subscribers = JSON.parse(localStorage.getItem('subscribers'));
          this.selectedSubscriber = localStorage.getItem('curSubscriber') ? localStorage.getItem('curSubscriber') : this.subscribers[0].id;
          this.sites = JSON.parse(localStorage.getItem('sites'));
          this.selectedSite = localStorage.getItem('curSite') ? localStorage.getItem('curSite') : '';
        }else{
          this.getSubscribers();  
        }
    }

    getSubscribers() {
    this.coreService.getRequest('api/subscriber?term=PSB Academy')
    .then((data) => {
        if (data && data.success) {
          localStorage.setItem('subscribers', JSON.stringify(data.data.subscribers.data));
          if(this.userDetail.user.subscriber_id){
            for (var i = 0; i < data.data.subscribers.data.length; i++) {
              if(this.userDetail.user.subscriber_id == data.data.subscribers.data[i].id){
                this.subscribers.push(data.data.subscribers.data[i]);
                this.selectedSubscriber = data.data.subscribers.data[i].id;
                this.getSites(this.selectedSubscriber);
                break;
              }
            }
          }else{
            this.subscribers = data.data.subscribers.data;
            this.selectedSubscriber = this.subscribers[0].id;
            this.getSites(this.selectedSubscriber);
          }
          localStorage.setItem('subscribers', JSON.stringify(this.subscribers));
          console.log(this.subscribers);
        }
    },(error) => {
        let data = JSON.parse(error._body);
        console.log("error: " + data.message);
    });
  }

  getSites(subscriber_id) {
    localStorage.setItem('curSubscriber', subscriber_id);
    let url = 'api/site';
    if(subscriber_id){
      url += '?subscriber_id='+subscriber_id;
    }else{
      localStorage.setItem('sites', '');
      localStorage.setItem('curSite', '');
      this.sites = [];
      this.selectedSite = '';
      return;
    }
    this.coreService.getRequest(url)
    .then((data) => {
      if (data && data.success) {
        if(data.data.sites.data.length > 0){
          if(this.userDetail.site_access.length > 0){
            for (var i = 0; i < data.data.sites.data.length; i++) {
              for (var j = 0; j < this.userDetail.site_access.length; j++) {
                if(this.userDetail.site_access[j].site_id == data.data.sites.data[i].id){
                  this.sites.push(data.data.sites.data[i]);
                  break;
                }
              }
            }
            if(this.userDetail.site_access.length == 1){
              this.selectedSite = data.data.sites.data[0].id;
              localStorage.setItem('curSite', data.data.sites.data[0].id);
            }
          }else{
            this.sites = data.data.sites.data;
            this.selectedSite = '';
            if(this.sites.length == 1){
              this.selectedSite = this.sites[0].id;  
            }
            localStorage.setItem('curSite', this.selectedSite);
          }
          localStorage.setItem('sites', JSON.stringify(this.sites));
          if(!localStorage.getItem('login')){
            location.reload();
          }else{
            localStorage.removeItem('login')
          }
        }
      }
    },(error) => {
        let data = JSON.parse(error._body);
        console.log("error: " + data.message);
    });
  }

  setSite(site_id) {
    this.selectedSite = site_id;
    localStorage.setItem('curSite', site_id);
    location.reload();
  }

    // getRolePermission(role_id) {
    //     this.coreService.getRequest('api/roles-permissions/' + role_id)
    //         .then((data) => {
    //             if (data && data.success) {
    //                 localStorage.setItem('userRolePerm', JSON.stringify(data.data));
    //                 this.theUserRolePermn = data.data.
    //             }
    //         },
    //             (error) => {
    //                 console.log(error);
    //                 let data = JSON.parse(error._body);
    //             })
    // }

    logout() {
        var url = 'api/user/logout';
        let postParams = new HttpParams;

        this.coreService.postRequest(url, postParams)
            .then(data => {
                localStorage.removeItem('user_login_data');
                localStorage.removeItem('userRolePerm');
                localStorage.removeItem('curSubscriber');
                localStorage.removeItem('curSite');
                localStorage.removeItem('subscribers');
                localStorage.removeItem('sites');
                localStorage.removeItem('curSchool');
                this.router.navigate(['/login']);
            }, error => {
                console.log(error);
                localStorage.removeItem('user_login_data');
                localStorage.removeItem('userRolePerm');
                localStorage.removeItem('curSubscriber');
                localStorage.removeItem('curSite');
                localStorage.removeItem('subscribers');
                localStorage.removeItem('sites');
                localStorage.removeItem('curSchool');
                this.router.navigate(['/login']);
            });
        // localStorage.removeItem('user_login_data');
    }

    checkPermissionName(perm_name) {
      let userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
      let data = userRolePerm;
      // console.log(perm_name.length);
      if(perm_name.length > 1){
        let permissionFlag = false;
        for (var j = 0; j < perm_name.length; j++){
          for (var i = 0; i < data.length; i++) {
            if (perm_name[j] == data[i].permission_name) {
              permissionFlag = true;
              break;
            }
          }
        }
        if(!permissionFlag){
          return false;
        }
        return true;
      }else{
        for (var i = 0; i < data.length; i++) {
          if (perm_name[0] == data[i].permission_name) {
            return true;
          }
        }
      }
    }

}
