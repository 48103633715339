import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { post } from 'selenium-webdriver/http';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {

    changePasswordForm: FormGroup;
    submitted = false;
    errorMsg = null;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        public coreService: CoreService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            let id = params['id'];
        }),
            this.changePasswordForm = this.formBuilder.group({
                old_password: ['', Validators.required],
                new_password_confirmation: ['', Validators.required],
                new_password: ['', Validators.required],
            });
    }
    get f() { return this.changePasswordForm.controls; }

    changePassword(params) {

        this.submitted = true;
        if (this.changePasswordForm.valid) {
            this.coreService.postRequest('api/user/change_password', params)
                .then((data) => {
                	localStorage.removeItem('user_login_data');
	                localStorage.removeItem('userRolePerm');
	                localStorage.removeItem('curSubscriber');
	                localStorage.removeItem('curSite');
	                localStorage.removeItem('subscribers');
	                localStorage.removeItem('sites');
	                localStorage.removeItem('curSchool');
                    alert('Password Changed. Please Login Again');
                    this.router.navigate(['/login']);
                },
                    (error) => {
                        console.log(error);
                        let data = JSON.parse(error._body);
                        alert('Wrong Password Information :' + data.message);
                    })
        }
    }

    onSubmit() {

        let postParams = this.changePasswordForm.value;

        if (postParams.new_password == postParams.new_password_confirmation) {
            this.changePassword(postParams);
        } else {
            this.errorMsg = "Password does not match";
        }

    }

    onChange() {
        this.errorMsg = null;
    }
}
