import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-add-lesson',
  templateUrl: './add-lesson.component.html',
  styleUrls: ['./add-lesson.component.css']
})
export class AddLessonComponent implements OnInit {

  addLessonForm: FormGroup;
  submitted = false;
  lecturers = [];
  message: string;
  selectAllCheck_day = false;

  startdate = moment().format('YYYY-MM-DD');
  enddate = moment().add(1, 'day').format('YYYY-MM-DD');
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  timeOptions = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30"
  ];

  startTime = '00:00';
  endTime = '00:00';

  days = [
    {
      'value': 'mon',
      'name': 'Monday',
      'type': 'weeks'
    }, {
      'value': 'tue',
      'name': 'Tuesday',
      'type': 'weeks'
    }, {
      'value': 'wed',
      'name': 'Wednesday',
      'type': 'weeks'
    }, {
      'value': 'thu',
      'name': 'Thursday',
      'type': 'weeks'
    }, {
      'value': 'fri',
      'name': 'Friday',
      'type': 'weeks'
    }, {
      'value': 'sat',
      'name': 'Saturday',
      'type': 'weeks'
    }, {
      'value': 'sun',
      'name': 'Sunday',
      'type': 'weeks'
    }
  ];

  selectedDay = [];

  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');
  sites = JSON.parse(localStorage.getItem('sites'));
  selectedSite: any = [];
  selectAllCheck_site: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) { 
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    console.log(this.sites);
  }

  ngOnInit() {
    this.addLessonForm = this.formBuilder.group({
      course_name: ['', Validators.required],
      course_code: ['', Validators.required],
      course_day: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      conducted_by: ['', Validators.required],
      subscriber_id: this.subscriber_id,
      site_ids: ['', Validators.required],
    });
    this.getLecturers();
  }
  get f() { return this.addLessonForm.controls; }

  getLecturers() {
    var url = 'api/lecturer?subscriber_id='+this.subscriber_id;
    this.coreService.getRequest(url)
    .then(data => {
      this.lecturers = data.data.employees;
    }, error => {
        console.log(error);
    });
  }

  onSubmit() {
    this.submitted = true;
    this.addLessonForm.patchValue({
      site_ids: JSON.stringify(this.selectedSite),
      course_day: JSON.stringify(this.selectedDay),
      start_date: moment(this.formatter.format(this.fromDate)).format('YYYY-MM-DD'),
      end_date: moment(this.formatter.format(this.toDate)).format('YYYY-MM-DD'),
      start_time: this.startTime,
      end_time: this.endTime,
    });
    // stop here if form is invalid
    if (this.addLessonForm.valid) {
      console.log(this.addLessonForm.value);
      let postParams = this.addLessonForm.value;
      this.coreService.postRequest('api/school_course', postParams)
      .then((data) => {
        alert('Successfully Added Programme');
        this.router.navigate(['/student/lesson']);
      },
      (error) => {
        console.log(error);
        let data = JSON.parse(error._body);
        alert("error: " + data.errors[0]);
      });
    }
  }

  onReset() {
    this.submitted = false;
    this.addLessonForm.reset();
  }

  onSelectAll(type) {
    if(type == 'day'){
      let groupDay = [];
      if (this.selectAllCheck_day) {
        for (var i = 0; i < this.days.length; i++) {
          groupDay.push(this.days[i].value);
        }
      }
      this.selectedDay = groupDay;
    }else{
      let groupSite = [];
      if (this.selectAllCheck_site) {
        for (var i = 0; i < this.sites.length; i++) {
          groupSite.push(this.sites[i].name);
        }
      }
      this.selectedSite = groupSite;
    }
    
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || this.fromDate == date)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
