import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CoreService } from '../core.service';

import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	copyRightYear: any = '';
	@ViewChild('modal_content', { static: false })
	private modelContent: TemplateRef<any>;
	modal_body: string = '';
	modal_title: string = '';
	modalDetails: any = {
		modal_title: '',
		modal_body: ''
	};
	schools: any = [];

	private formGroup: FormGroup;
	submitAttemped: boolean = false;

	newWindow: Window;
	pollingId: any = '';

	constructor(private formBuilder: FormBuilder,
		private modalService: NgbModal,
		private coreService: CoreService,
		private router: Router,
		private zone: NgZone,
	) {
		this.copyRightYear = moment().format('YYYY');

		this.formGroup = this.formBuilder.group({
			email: ['', Validators.required],
			password: ['', Validators.required]
		});
	}

	ngOnInit() {
		this.getSchools();
	}

	getSchools() {
		this.coreService.getRequest('api/school')
	    .then(data => {
	        this.schools = data.data.schools.data;
	    }, error => {
	        console.log(error);
	    });
	}

	next() {
		if (this.formGroup.valid) {
			let role_id = '';
			let postParams = this.formGroup.value;
			this.coreService.postRequest('api/user/login', postParams)
				.then(async (data) => {
					localStorage.setItem('userRolePerm', JSON.stringify(data.data.role_permission));
					localStorage.setItem('user_login_data', JSON.stringify(data.data));
					if (data && data.success) {
						try {
							let queryParams = new HttpParams();
							queryParams = queryParams.set('portal', 'psb-admin');
							const result = await this.coreService.getRequest('api/check_role_by_portal?' + queryParams.toString());
							if (result.success) {
								// if (data.data.school.length > 0) {
								// localStorage.setItem('curSchool', data.data.school[0].id);
								// }
								if (data.data.school.length > 0) {
									let schoolIds = [];
									data.data.school.forEach(s => {
										schoolIds.push(s.id);
									});
									localStorage.setItem('curSchool', schoolIds.join(','));
								}
								localStorage.setItem('login', 'true');
								this.router.navigate([result.data.navigate_url]);
							}
						} catch (err) {
							console.log(err);
							let data = JSON.parse(err._body);
							this.modalDetails.modal_title = 'Alert';
							this.modalDetails.modal_body = data.errors.length > 1 ? data.errors.join('<br />') : data.errors[0];
							this.modalService.open(this.modelContent, { centered: true });
						}
					}
					else {
						this.modalDetails.modal_title = 'Alert';
						this.modalDetails.modal_body = data.errors.length > 1 ? data.errors.join('<br />') : data.errors[0];
						this.modalService.open(this.modelContent, { centered: true });
					}
				},
					(error) => {
						console.log(error);
						let data = JSON.parse(error._body);
						this.modalDetails.modal_title = 'Alert';
						this.modalDetails.modal_body = data.errors.length > 1 ? data.errors.join('<br />') : data.errors[0];
						this.modalService.open(this.modelContent, { centered: true });
					})

			// this.coreService.getRequest('api/roles-permissions/' + role_id)
			// 	.then((data) => {
			// 		if (data && data.success) {
			// 			localStorage.setItem('userRolePerm', JSON.stringify(data.data));
			// 		}
			// 	},
			// 		(error) => {
			// 			console.log(error);
			// 			let data = JSON.parse(error._body);
			// 		})

		}
		else {
			this.submitAttemped = true;
			this.modalDetails.modal_title = 'Alert';
			this.modalDetails.modal_body = 'Invalid field(s) detected, please amend and try again.';
			this.modalService.open(this.modelContent, { centered: true });
		}
	}

	getRolePermission(role_id) {
		this.coreService.getRequest('api/roles-permissions/' + role_id)
		.then((data) => {
			if (data && data.success) {
				localStorage.setItem('userRolePerm', JSON.stringify(data.data));
			}
		},
		(error) => {
			console.log(error);
			let data = JSON.parse(error._body);
		})
	}

	popUpCenter() {
		let w = 570;
		let h = 520;

		// Fixes dual-screen position                             Most browsers      Firefox
		const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

		const width = window.innerWidth;
		const height = window.innerHeight;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;

		return `
		  toolbar=no,
		  menubar=no,
		  scrollbars=no,
		  location=no,
		  directories=no,
		  resizable=yes,
		  scrollbars=yes,
		  status=no,
		  width=${w / systemZoom},
		  height=${h / systemZoom},
		  top=${top},
		  left=${left}
		`;
	  }

	async azureLogin() {
		let ref_id = '';

		// generate ref_id api call
		try {
		  const generateRefResult = await this.coreService.postRequest('api/azure_login_ref?portal=admin', {});
		  if (generateRefResult.success) {
			ref_id = generateRefResult.data.azure_login_ref.ref_id;
		  }
		} catch (error) {
		  console.log(error)
		  let data = error.error;
		  alert("error " + data.errors[0]);
		  return;
		}

		let queryParams = new HttpParams();
		queryParams = queryParams.set('ref_id', ref_id);

		// goole active directory login
		let options = this.popUpCenter();
		this.newWindow = window.open(`${environment.apiEndpoint}api/user/social/azure?${queryParams.toString()}`, '_blank', options) || new Window();
		this.newWindow!.focus();

		// backend auto handle azure sso callback
		// check azure_login_ref record
		// start after 2 second to wait for server process, instead of waiting 5 seconds afterwards
		this.refPollingRequest(ref_id);
	}

	async refPollingRequest(ref_id: string) {
		localStorage.removeItem('auth_token'); //JSON.stringify(data.azure_login_ref.token));
		try {
			// check azure login reference status
			const getRefResult = await this.coreService.getRequest(`api/azure_login_ref/${ref_id}`);
			if (getRefResult.success) {
				const data = getRefResult.data;
				if (data.azure_login_ref.status === 'success') {
					Promise.all([
						localStorage.setItem('userRolePerm', JSON.stringify(data.role_permission)),
						localStorage.setItem('user_login_data', JSON.stringify({
						token: data.azure_login_ref.token,
						user: data.user,
						role: data.role,
						role_permission: data.role_permission,
						employee: data.employee,
						site_access: data.site_access,
						school: data.school
						})),
					]).then(async () => {
					// use ng zone to suppress warning
					await this.zone.run(async () => {
            try {
				let queryParams = new HttpParams();
				queryParams = queryParams.set('portal', 'psb-admin');
				const result = await this.coreService.getRequest('api/check_role_by_portal?' + queryParams.toString());
              if (result.success) {
                if (data.school.length > 0) {
					let schoolIds = [];
					data.school.forEach(s => {
						schoolIds.push(s.id);
					});
					localStorage.setItem('curSchool', schoolIds.join(','));
				}
                localStorage.setItem('login', 'true');
                this.router.navigate([result.data.navigate_url]);
              }
			} catch (err) {
				console.log(err);
				let data = JSON.parse(err._body);
				this.modalDetails.modal_title = 'Alert';
				this.modalDetails.modal_body = data.errors.length > 1 ? data.errors.join('<br />') : data.errors[0];
				this.modalService.open(this.modelContent, { centered: true });
			}

			this.newWindow.close();

            // let allowRoles = ['super_admin', 'admin', 'school_admin', 'data_report', 'fac'];
						// if(allowRoles.includes(data.role[0].name)){
            //   if(data.role[0].name == 'school_admin'){

            //   if(data.school.length == 0){
            //     this.router.navigate(['/login']);
            //     localStorage.removeItem('userRolePerm');
            //     localStorage.removeItem('user_login_data');
            //     this.modalDetails.modal_title = 'Alert';
            //     this.modalDetails.modal_body = 'Unauthorized user role.';
            //     this.modalService.open(this.modelContent, { centered: true });
            //     return;
            //   }

            //   localStorage.setItem('curSchool', data.school[0].id);

            //     // for (let i = 0; i < this.schools.length; i++) {
            //     // 	// console.log(this.schools[i].school_code);
            //     // 	// console.log(data.user.name.indexOf(this.schools[i].school_code));
            //     // 	if(data.school.indexOf(this.schools[i].school_code) !== -1){
            //     // 		allowLogin = true;
            //     //     localStorage.setItem('curSchool', data.school[0].id);
            //     // 		// console.log(localStorage.getItem('curSchool'));
            //     // 	}
            //     // }
            //   }
            //   if(data.role[0].name == 'data_report'){
            //     this.router.navigate(['/report/student-attendance']);
            //   }else if (data.role[0].name == 'fac') {
            //     this.router.navigate(['/visitor/list']);
            //   }else{
            //     this.router.navigate(['/employee/list']);
            //   }
            //   await localStorage.setItem('login', 'true');
						// }else{
            //   this.router.navigate(['/login']);
            //   localStorage.removeItem('userRolePerm');
            //   localStorage.removeItem('user_login_data');
            //   this.modalDetails.modal_title = 'Alert';
            //   this.modalDetails.modal_body = 'Unauthorized user role.';
            //   this.modalService.open(this.modelContent, { centered: true });
						// }
					});
				});
				} else if (data.azure_login_ref.status === 'failed') {
				localStorage.setItem('isLoggedIn', 'true');

				// use ng zone to suppress warning
				await this.zone.run(async () => {
				this.newWindow.close();
				alert('Login Failed: '+data.azure_login_ref.failed_reason);
				});
				} else {
				// azure login reference is still in pending
				this.pollingTimeout(ref_id);
				}
		  }
		} catch (error) {
		  console.log(error)
		  let data = error.error;
		  alert("error " + data.errors[0]);
		}
	}

	pollingTimeout(ref_id: string) {
		this.pollingId = setTimeout(async () => {
		  await this.refPollingRequest(ref_id);
		}, 2000);
	}

}
