import { ChangeDetectionStrategy, Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core'; import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CoreService } from '../core.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})

export class EventComponent implements OnInit {
  
  userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
  advancedSearchForm: FormGroup;
  delete_id = -1;
  onDeleteType = false;
  onResetType = false;
  reset_id = -1;
  tableRecords = [];
  tableParams = new HttpParams();
  tableTotalRecords = 0;
  tableCurPage = 1;
  tablePageSize = 10;
  sortby = '';
  DESC = false;
  searchName = '';

  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');

  @ViewChild('modal_content', { static: false })
  private modelContent!: TemplateRef<any>;
  modal_body: string = '';
  modal_title: string = '';
  modalDetails: any = {
      modal_title: '',
      modal_body: '',
      modal_type: ''
  };

  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private modalService: NgbModal,
      private coreService: CoreService,
      private sanitizer: DomSanitizer) { }

  ngOnInit() {

      this.advancedSearchForm = this.formBuilder.group({
          searchName: ['']
      });
      this.resetAndRefreshTable();
  }

  resetAndRefreshTable(action = '') {
      // if (this.searchTerm) {
      //   this.tableParams = this.tableParams.set('term', this.searchTerm);
      // }
      // else {
      //   if (this.tableParams.has('term')) {
      //     this.tableParams = this.tableParams.delete('term');
      //   }
      // }
      if (action == 'modal_call_to_action') {
          this.tableCurPage = 1;
      }
      this.tableParams = this.tableParams.set('page', this.tableCurPage.toString());
      this.tableParams = this.tableParams.set('per_page', this.tablePageSize.toString());
      this.getTableData(this.tableParams);
  }

  getTableData(params: HttpParams) {
      if (this.searchName != '') {
        params = params.set('name', this.searchName);
      }
      if(this.subscriber_id){
        params = params.set('subscriber_id', this.subscriber_id);
        if(this.site_id){
           params = params.set('site_id', this.site_id);
        }
      }

      var url = 'api/event?' + params.toString();
      this.coreService.getRequest(url)
      .then(data => {
        this.tableRecords = data.data.events.data;
        this.tableTotalRecords = data.data.events.total;
      }, error => {
          console.log(error);
      });
  }

  onDelete(id) {
      this.onDeleteType = true;
      this.onResetType = false;
      this.delete_id = id;
      this.modalDetails.modal_title = 'Alert';
      this.modalDetails.modal_body = "Are you sure you want to delete this event?";
      this.modalDetails.modal_type = "modal_call_to_action";
      this.modalService.open(this.modelContent, { centered: true });
  }

  modalCallToAction() {
      if (this.modalDetails.modal_type == 'modal_call_to_action') {
          if (this.onDeleteType == true) {
              this.DeleteCallToAction();
          }
      }
  }

  DeleteCallToAction() {
      this.modalService.dismissAll();
      if (this.modalDetails.modal_type == 'modal_call_to_action') {
        //   this.coreService.deleteRequest('api/student/' + this.delete_id)
        //       .then(data => {
        //           if (data.success) {
        //               alert('Successfully Deleted Student');
        //               this.resetAndRefreshTable();
        //               location.reload();
        //           }
        //       }, error => {
        //           console.log(error);
        //           alert('Delete Student Fail');
        //       })
      }
  }

  onAdvancedSearch() {
      let postParams = this.advancedSearchForm.value
      this.searchName = postParams.searchName;
      this.resetAndRefreshTable();
  }

  getstudent(id) {
      // this.coreService.getRequest('api/student/' + id)
      //     .then((data) => {
      //         if (data && data.success) {
      //             this.studentData = data.data.student;
      //         }
      //     },
      //         (error) => {
      //             console.log(error);
      //             let data = JSON.parse(error._body);
      //         });
  }

  sorting(sortby) {
      this.sortby = sortby;
      if (this.DESC == true) {
          this.DESC = false;
      } else {
          this.DESC = true;
      }
      this.resetAndRefreshTable();
  }

  checkPermissionName(perm_name) {
      let userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
      let data = userRolePerm;

      for (var i = 0; i < data.length; i++) {
          if (perm_name == data[i].permission_name) {
              return true;
          }
      }
  }
}
