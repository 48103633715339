import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-lesson',
  templateUrl: './edit-lesson.component.html',
  styleUrls: ['./edit-lesson.component.css']
})
export class EditLessonComponent implements OnInit {

  editLessonForm: FormGroup;
  lesson: any = [];
  submitted = false;
  lecturers = [];
  message: string;
  selectAllCheck_day = false;

  startdate = moment().format('YYYY-MM-DD');
  enddate = moment().add(1, 'day').format('YYYY-MM-DD');
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  timeOptions = [
    "00:00:00",
    "00:30:00",
    "01:00:00",
    "01:30:00",
    "02:00:00",
    "02:30:00",
    "03:00:00",
    "03:30:00",
    "04:00:00",
    "04:30:00",
    "05:00:00",
    "05:30:00",
    "06:00:00",
    "06:30:00",
    "07:00:00",
    "07:30:00",
    "08:00:00",
    "08:30:00",
    "09:00:00",
    "09:30:00",
    "10:00:00",
    "10:30:00",
    "11:00:00",
    "11:30:00",
    "12:00:00",
    "12:30:00",
    "13:00:00",
    "13:30:00",
    "14:00:00",
    "14:30:00",
    "15:00:00",
    "15:30:00",
    "16:00:00",
    "16:30:00",
    "17:00:00",
    "17:30:00",
    "18:00:00",
    "18:30:00",
    "19:00:00",
    "19:30:00",
    "20:00:00",
    "20:30:00",
    "21:00:00",
    "21:30:00",
    "22:00:00",
    "22:30:00",
    "23:00:00",
    "23:30:00"
  ];

  startTime = '00:00:00';
  endTime = '00:00:00';

  days = [
    {
      'value': 'mon',
      'name': 'Monday',
      'type': 'weeks'
    }, {
      'value': 'tue',
      'name': 'Tuesday',
      'type': 'weeks'
    }, {
      'value': 'wed',
      'name': 'Wednesday',
      'type': 'weeks'
    }, {
      'value': 'thu',
      'name': 'Thursday',
      'type': 'weeks'
    }, {
      'value': 'fri',
      'name': 'Friday',
      'type': 'weeks'
    }, {
      'value': 'sat',
      'name': 'Saturday',
      'type': 'weeks'
    }, {
      'value': 'sun',
      'name': 'Sunday',
      'type': 'weeks'
    }
  ];

  selectedDay = [];

  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) { 
    // NEED TO CHANGE
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
  }

  ngOnInit() {
    this.editLessonForm = this.formBuilder.group({
      course_name: ['', Validators.required],
      course_code: ['', Validators.required],
      course_day: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      conducted_by: ['', Validators.required],
    });
    this.getCourse();
    this.getLecturers();
  }
  get f() { return this.editLessonForm.controls; }

  getLecturers() {
    var url = 'api/lecturer?subscriber_id='+this.subscriber_id;
    this.coreService.getRequest(url)
    .then(data => {
      this.lecturers = data.data.employees;
    }, error => {
        console.log(error);
    });
  }

  getCourse() {
    var url = 'api/school_course/'+this.route.snapshot.params['id'];
    this.coreService.getRequest(url)
    .then(data => {
      if(data && data.success){
        this.lesson = data.data.school_course;
        let course_day = JSON.parse(data.data.school_course.course_day);
        let groupDay = [];
        for(var i = 0; i < course_day.length; i++){
          for(var j = 0; j < this.days.length; j++){
            if(course_day[i] == this.days[j].value){
              groupDay.push(this.days[j].value);
              break;
            }
          }
        }
        this.selectedDay = groupDay;
        this.lesson.course_day = JSON.stringify(this.selectedDay);
        this.fromDate = this.getNgbDate(this.lesson.start_date);
        this.toDate = this.getNgbDate(this.lesson.end_date);
        this.startTime = this.lesson.start_time;
        this.endTime = this.lesson.end_time;
        this.lesson.start_date = this.getFormatterDate(this.fromDate);
        this.lesson.end_date = this.getFormatterDate(this.toDate);
        
        this.editLessonForm.patchValue(this.lesson);
      }
    }, error => {
        console.log(error);
    });
  }

  getNgbDate(date) {
    return new NgbDate(
      moment(date).year(),
      moment(date).month() + 1,
      moment(date).date()
    );
  }

  getFormatterDate(NgbDate) {
    return moment(this.formatter.format(NgbDate)).format('YYYY-MM-DD');
  }

  onSubmit() {
    this.submitted = true;
    this.editLessonForm.patchValue({
      course_day: JSON.stringify(this.selectedDay),
      start_date: moment(this.formatter.format(this.fromDate)).format('YYYY-MM-DD'),
      end_date: moment(this.formatter.format(this.toDate)).format('YYYY-MM-DD'),
      start_time: this.startTime,
      end_time: this.endTime,
    });
    // stop here if form is invalid
    if (this.editLessonForm.valid) {
      let postParams = this.editLessonForm.value;
      this.coreService.putRequest('api/school_course', postParams)
      .then((data) => {
        alert('Successfully Updated Programme');
        this.router.navigate(['/student/lesson']);
      },
        (error) => {
          console.log(error);
          let data = JSON.parse(error._body);
          alert("error: " + data.message);
        })
    }
  }

  onReset() {
    this.submitted = false;
    this.editLessonForm.reset();
  }

  onSelectAll(type) {
    let groupDay = [];
    if (this.selectAllCheck_day) {
      for (var i = 0; i < this.days.length; i++) {
        groupDay.push(this.days[i].name);
      }
    }
    this.selectedDay = groupDay;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || this.fromDate == date)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
