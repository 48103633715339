import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import DownloadHelper from '../../download-helper';

@Component({
  selector: 'app-batch-upload-student',
  templateUrl: './batch-upload-student.component.html',
  styleUrls: ['./batch-upload-student.component.css']
})
export class BatchUploadStudentComponent implements OnInit {
	batchUploadForm: FormGroup;
	files: any = [];
	errors: any = [];
  errorIsObject = false;
  submitted: boolean = false;
  batchUploadFormValid = false;

  constructor(
  	private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  	this.batchUploadForm = this.formBuilder.group({
      file: ['', Validators.required],
    });
  }

  setFile(files){
    this.updateFileNames(files);
    if (files.length === 0){
      this.files = [];
      return;
    }
    
  	this.files = files;
  }

  updateFileNames(files) {
    let fileNames = Array.from(files).map((file: any) => file.name).join(', ');
    let customFileLabel = document.getElementById('customFileLabel');
    customFileLabel.innerHTML = fileNames || 'Choose File';
  }

  onDownload() {
    this.coreService.downloadRequest('api/upload_template?type=student')
    .then(data => {
      // application/zip
      let options = { type: 'application/zip' };
      var contentDisposition = data.headers.get('Content-Disposition');
      var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      DownloadHelper.createAndDownloadBlobFile(data._body, options, filename);
    }, error => {
      let data = JSON.parse(error._body);
      alert("error: " + data.message);
    });
  }

  onUpload() {
  	this.submitted = true;
  	let fileList: FileList = this.files;
	  if (fileList.length > 0) {
      this.batchUploadFormValid = true;
	    let file: File = fileList[0];
	    let formData: FormData = new FormData();
	    formData.append('archive', file, file.name);
	    formData.append('filetype', 'zip');
	    // console.log(formData);
	    this.coreService.postRequest('api/student_batch', formData)
      .then((data) => {
        alert('Successfully Upload Students Data');
        this.router.navigate(['/student/list']);
      },
      (error) => {
        this.batchUploadFormValid = false;
        console.log(error);
        let data = JSON.parse(error._body);
        this.errors = data.errors;
        if(data.errors[0] == undefined){
          this.errorIsObject = true;
        }
        if(!this.errorIsObject){
          alert("error: " + data.errors[0]);  
        }
      });
	  }else{
      this.batchUploadFormValid = false;
	  	return;
	  }
  }

}
