import { ChangeDetectionStrategy, Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core'; import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CoreService } from '../../core.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-visitor-pending',
  templateUrl: './visitor-pending.component.html',
  styleUrls: ['./visitor-pending.component.css']
})
export class VisitorPendingComponent implements OnInit {

  userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
  advancedSearchForm: FormGroup;
  delete_id = -1;
  onDeleteType = false;
  onResetType = false;
  reset_id = -1;
  tableRecords = [];
  tableParams = new HttpParams();
  tableTotalRecords = 0;
  tableCurPage = 1;
  tablePageSize = 20;
  sortby = '';
  DESC = false;
  studentData: [];
  searchName = '';
  // searchTerm = '';
  startdate = moment().format('YYYY-MM-DD');
  enddate = moment().add(1, 'day').format('YYYY-MM-DD');
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  @ViewChild('modal_content', { static: false })
  private modelContent!: TemplateRef<any>;
  modal_body: string = '';
  modal_title: string = '';
  modalDetails: any = {
    modal_title: '',
    modal_body: '',
    modal_type: ''
  };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private coreService: CoreService,
    private sanitizer: DomSanitizer,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
  }

  timeOptions = [
    "00:00:00",
    "00:30:00",
    "01:00:00",
    "01:30:00",
    "02:00:00",
    "02:30:00",
    "03:00:00",
    "03:30:00",
    "04:00:00",
    "04:30:00",
    "05:00:00",
    "05:30:00",
    "06:00:00",
    "06:30:00",
    "07:00:00",
    "07:30:00",
    "08:00:00",
    "08:30:00",
    "09:00:00",
    "09:30:00",
    "10:00:00",
    "10:30:00",
    "11:00:00",
    "11:30:00",
    "12:00:00",
    "12:30:00",
    "13:00:00",
    "13:30:00",
    "14:00:00",
    "14:30:00",
    "15:00:00",
    "15:30:00",
    "16:00:00",
    "16:30:00",
    "17:00:00",
    "17:30:00",
    "18:00:00",
    "18:30:00",
    "19:00:00",
    "19:30:00",
    "20:00:00",
    "20:30:00",
    "21:00:00",
    "21:30:00",
    "22:00:00",
    "22:30:00",
    "23:00:00",
    "23:30:00",
  ];

  startTime = '00:00:00';
  endTime = '00:00:00';

  ngOnInit() {

    this.advancedSearchForm = this.formBuilder.group({
      searchName: ['']
    });
    // this.resetAndRefreshTable();
  }

  resetAndRefreshTable(action = '') {
    // if (this.searchTerm) {
    //   this.tableParams = this.tableParams.set('term', this.searchTerm);
    // }
    // else {
    //   if (this.tableParams.has('term')) {
    //     this.tableParams = this.tableParams.delete('term');
    //   }
    // }
    if (action == 'modal_call_to_action') {
      this.tableCurPage = 1;
    }
    this.tableParams = this.tableParams.set('page', this.tableCurPage.toString());
    this.tableParams = this.tableParams.set('per_page', this.tablePageSize.toString());
    this.getTableData(this.tableParams);
  }

  getTableData(params: HttpParams) {
    if (this.searchName != '') {
      params = params.set('name', this.searchName);
    }
    var url = 'api/student?' + params.toString();
    this.coreService.getRequest(url + params.toString())
      .then(data => {
        for (var i = 0; i < data.data.students.data.length; i++) {
          data.data.students.data[i]['image_safe'] = this.sanitizer.bypassSecurityTrustResourceUrl(data.data.students.data[i].image)
        }
        this.tableRecords = data.data.students.data;
        this.tableTotalRecords = data.data.students.total;
      }, error => {
        console.log(error);
      });
  }

  onDelete(id) {
    this.onDeleteType = true;
    this.onResetType = false;
    this.delete_id = id;
    this.modalDetails.modal_title = 'Alert';
    this.modalDetails.modal_body = "Are you sure you want to delete this student ?";
    this.modalDetails.modal_type = "modal_call_to_action";
    this.modalService.open(this.modelContent, { centered: true });
  }

  modalCallToAction() {
    if (this.modalDetails.modal_type == 'modal_call_to_action') {
      if (this.onDeleteType == true) {
        this.DeleteCallToAction();
      }
    }
  }

  DeleteCallToAction() {
    this.modalService.dismissAll();
    if (this.modalDetails.modal_type == 'modal_call_to_action') {
      this.coreService.deleteRequest('api/student/' + this.delete_id)
        .then(data => {
          if (data.success) {
            alert('Successfully Deleted Student');
            this.resetAndRefreshTable();
            location.reload();
          }
        }, error => {
          console.log(error);
          alert('Delete Student Fail');
        })
    }
  }

  onAdvancedSearch() {
    let postParams = this.advancedSearchForm.value
    this.searchName = postParams.searchName;
    this.resetAndRefreshTable();
  }

  getstudent(id) {
    // this.coreService.getRequest('api/student/' + id)
    //     .then((data) => {
    //         if (data && data.success) {
    //             this.studentData = data.data.student;
    //         }
    //     },
    //         (error) => {
    //             console.log(error);
    //             let data = JSON.parse(error._body);
    //         });
  }

  sorting(sortby) {
    this.sortby = sortby;
    if (this.DESC == true) {
      this.DESC = false;
    } else {
      this.DESC = true;
    }
    this.resetAndRefreshTable();
  }

  checkPermissionName(perm_name) {
    let userRolePerm = JSON.parse(localStorage.getItem('userRolePerm'));
    let data = userRolePerm;

    for (var i = 0; i < data.length; i++) {
      if (perm_name == data[i].permission_name) {
        return true;
      }
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || this.fromDate == date)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
