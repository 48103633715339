import { Injectable } from '@angular/core';
import { NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class VisitorParameterService {
	private _page:number;
	private _per_page:number;
  private _total_records:number;
	private _searchTerm: string = '';
	private _status: string = '';
	private _startDate: any;
	private _startTime: string = '00:00:00';
	private _endDate: any;
	private _endTime: string = '00:00:00';

  constructor(
  	private calendar: NgbCalendar,
  ) { 
  	this.page = 1;
  	this.perPage = 20;
  	this.startDate = calendar.getToday();
  	this.endDate = calendar.getNext(calendar.getToday(), 'd', 1);
  }

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    this._page = value;
  }

  get perPage(): number {
    return this._per_page;
  }

  set perPage(value: number) {
    this._per_page = value;
  }

  get totalRecords(): number {
    return this._total_records;
  }

  set totalRecords(value: number) {
    this._total_records = value;
  }

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  get startDate(): any {
    return this._startDate;
  }

  set startDate(value: any) {
    this._startDate = value;
  }

  get startTime(): string {
    return this._startTime;
  }

  set startTime(value: string) {
    this._startTime = value;
  }

  get endDate(): any {
    return this._endDate;
  }

  set endDate(value: any) {
    this._endDate = value;
  }

  get endTime(): string {
    return this._endTime;
  }

  set endTime(value: string) {
    this._endTime = value;
  }

  resetParameterService() {
    this.page = 1;
    this.perPage = 20;
    this.totalRecords = 0;
    this.status = '';
    this.searchTerm = '';
    this.startDate = this.calendar.getToday();
    this.startTime = '00:00:00';
    this.endDate = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
    this.endTime = '00:00:00';
  }
}
