import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmployeeParameterService {
	private _page:number;
	private _per_page:number;
  private _total_records:number;
	private _department: string = '';
	private _searchTerm: string = '';
  private _imageCheck: string = '';

  constructor() { 
  	this.page = 1;
  	this.perPage = 20;
  }

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    this._page = value;
  }

  get perPage(): number {
    return this._per_page;
  }

  set perPage(value: number) {
    this._per_page = value;
  }

  get totalRecords(): number {
    return this._total_records;
  }

  set totalRecords(value: number) {
    this._total_records = value;
  }

  get department(): string {
    return this._department;
  }

  set department(value: string) {
    this._department = value;
  }

  get imageCheck(): string {
    return this._imageCheck;
  }

  set imageCheck(value: string) {
    this._imageCheck = value;
  }

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
  }

  resetParameterService() {
    this.page = 1;
    this.perPage = 20;
    this.totalRecords = 0;
    this.department = '';
    this._imageCheck = '';
    this.searchTerm = '';
  }

}
