import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { CoreService } from '../../core.service';
import { EmployeeParameterService } from '../employee-parameter.service';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css']
})
export class EditEmployeeComponent implements OnInit {

  editEmployeeForm: FormGroup;
  submitted = false;
  employeeData = [];
  public imagePath;
  imgURL: any;
  image: any;
  imgBase64: any = '';
  public message: string;
  departments = [];
  schools = [];
  subscribers: any[];

  sites: any = [];
  selectedSite: any = [];
  selectAllCheck = false;

  userRole = JSON.parse(localStorage.getItem('user_login_data')).role[0];
  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private employeeParameterService: EmployeeParameterService
  ) { }

  ngOnInit() {
    this.imgURL = './assets/images/empty_photo_frame.png';
    this.editEmployeeForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      alias: '',
      sex: ['', Validators.required],
      email: ['', Validators.required],
      mobile: '',
      staff_id: '',
      department: ['', Validators.required],
      // nric: ['', Validators.required],
      status: ['', Validators.required],
      nric: '',
      image: '',
      subscriber_id: ['', Validators.required],
      school_id: '',
    });

    this.coreService.getRequest('api/staff/' + this.route.snapshot.params['id'])
      .then((data) => {
        if (data && data.success) {
          data.data.employee.image_safe = this.sanitizer.bypassSecurityTrustResourceUrl(data.data.employee.image);
          this.employeeData = data.data.employee;
        }
        var patchValue = this.employeeData;
        patchValue['image'] = '';
        this.editEmployeeForm.patchValue(patchValue);
        this.imgURL = this.employeeData['image_safe'];
        this.selectedSite = this.employeeData['ftga'];

        console.log(this.employeeData)
      },
        (error) => {
          console.log(error);
          let data = JSON.parse(error._body);
        });

    this.getDepartments();
    this.getSubscribers();
    this.getSchools();
    this.getSites();
  }
  get f() { return this.editEmployeeForm.controls; }

  getDepartments() {
    let url = 'api/department';

    if (this.subscriber_id) {
      url += '?subscriber_id=' + this.subscriber_id;
    }

    this.coreService.getRequest(url)
      .then((data) => {
        if (data && data.success) {
          // this.departments = data.data.departments.data;
          let departmentdata = [];
          for (var i = 0; i < data.data.departments.data.length; i++) {
            if (data.data.departments.data[i].status == 'active') {
              departmentdata.push(data.data.departments.data[i]);
            }
          }
          this.departments = departmentdata;
        }
      },
        (error) => {
          console.log(error);
          let data = JSON.parse(error._body);
          alert("error: " + data.message);
        });
  }

  getSites() {
    // this.coreService.getRequest('api/site?subscriber_id='+this.subscriber_id)
    // .then((data) => {
    //   if (data && data.success) {
    //     this.sites = data.data.sites.data;
    //   }
    // },(error) => {
    //     let data = JSON.parse(error._body);
    //     console.log("error: " + data.errors[0]);
    // });
    this.coreService.getRequest('api/facial_thermal_device_group?subscriber_id=' + this.subscriber_id)
      .then((data) => {
        if (data && data.success) {
          this.sites = data.data.facial_thermal_device_groups.data;
        }
      }, (error) => {
        let data = JSON.parse(error._body);
        console.log("error: " + data.errors[0]);
      });
  }

  getSubscribers() {
    this.coreService.getRequest('api/subscriber')
      .then((data) => {
        if (data && data.success) {
          this.subscribers = data.data.subscribers.data;
          if (this.subscriber_id) {
            for (var i = 0; i < data.data.subscribers.data.length; i++) {
              if (this.subscriber_id == data.data.subscribers.data[i].id) {
                this.subscribers[i].selected = true;
                this.editEmployeeForm.patchValue({
                  subscriber_id: this.subscribers[i].id
                });
                break;
              }
            }
          }
        }
      }, (error) => {
        let data = JSON.parse(error._body);
        console.log("error: " + data.message);
      });
  }

  getSchools() {
    let url = 'api/school';

    this.coreService.getRequest(url)
      .then((data) => {
        if (data && data.success) {
          for (var i = 0; i < data.data.schools.data.length; i++) {
            this.schools = data.data.schools.data;
          }
        }
      },
        (error) => {
          console.log(error);
          let data = JSON.parse(error._body);
          alert("error: " + data.message);
        });
  }

  onSubmit() {
    this.submitted = true;
    let postParams = this.editEmployeeForm.value;
    postParams.id = this.route.snapshot.params['id'];
    postParams.ftdg_ids = JSON.stringify(this.selectedSite);
    // stop here if form is invalid
    if (this.editEmployeeForm.valid) {
      // postParams.image = this.imgURL
      if (this.imgBase64) {
        postParams.image = this.imgBase64;
      }
      else {
        delete postParams.image;
      }
      this.coreService.putRequest('api/staff/' + this.route.snapshot.params['id'], postParams)
        .then((data) => {
          alert('Successfully Updated Employee');
          this.employeeParameterService.resetParameterService();
          this.router.navigate(['/employee/list']);
        },
          (error) => {
            console.log(error);
            let data = JSON.parse(error._body);
            alert("error: " + data.errors[0]);
          })
    }
  }

  onReset() {
    this.submitted = false;
    this.editEmployeeForm.reset();
  }

  preview(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgBase64 = event.target['result'];
        this.imgURL = event.target['result'];
      }
    }
  }

  onSelectAll() {
    let groupSite = [];
    if(this.selectAllCheck){
      for (var i = 0; i < this.sites.length; i++) {
        groupSite.push(this.sites[i].id);
      }
    }
    this.selectedSite = groupSite;
  }

}
