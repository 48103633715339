import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approve-visitor',
  templateUrl: './approve-visitor.component.html',
  styleUrls: ['./approve-visitor.component.css']
})
export class ApproveVisitorComponent implements OnInit {
  visitorRegistrationForm: FormGroup;
  submitted = false;
  studentData = [];
  imgURL: any;
  imgBase64: any = '';
  genders = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
  }

  ngOnInit() {
    this.visitorRegistrationForm = this.formBuilder.group({
      name: [{value:"Dwayne Johnson", disabled:true}, Validators.required],
      sex: [{value:"Male", disabled:true}, Validators.required],
      nric: [{value:"****1124M", disabled:true}, Validators.required],
      mobile: [{value:"88889999", disabled:true}, Validators.required],
      purpose: {value:"Visit John Cena", disabled:true},
      from: {value:"11/09/2020 17 : 00 : 00", disabled:true},
      to: {value:"11/09/2020 18 : 00 : 00", disabled:true},
      location: {value:"Main Entrance", disabled:true},
      status: {value:"Pending", disabled:true}
    });

    
    // this.coreService.getRequest('api/student/' + this.route.snapshot.params['id'])
    //   .then((data) => {
    //     if (data && data.success) {

    //       data.data.student.image_safe = this.sanitizer.bypassSecurityTrustResourceUrl(data.data.student.image);
    //       this.studentData = data.data.student
    //     }
    //     var patchValue = this.studentData;
    //     patchValue['image'] = '';
    //     this.visitorRegistrationForm.patchValue(patchValue);
    //     this.imgURL = this.studentData['image_safe']
    //   },
    //     (error) => {
    //       console.log(error);
    //       let data = JSON.parse(error._body);
    //     });

    this.genders.push({ type: 'M', name: "Male" }, { type: 'F', name: "Female" })
  }
  get f() { return this.visitorRegistrationForm.controls; }

  onSubmit() {
    this.submitted = true;
    let postParams = this.visitorRegistrationForm.value;
    if (this.visitorRegistrationForm.valid) {
      if (this.imgBase64) {
        postParams.image = this.imgBase64;
      }
      else {
        delete postParams.image;
      }

      //   this.coreService.putRequest('api/student/' + this.route.snapshot.params['id'], postParams)
      //     .then((data) => {
      //       alert('Student Profile Updated');
      //       this.router.navigate(['/student']);
      //     },
      //       (error) => {
      //         console.log(error);
      //         let data = JSON.parse(error._body);
      //         alert("error: " + data.message);
      //       })
    }
  }

  preview(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgBase64 = event.target['result'];
        this.imgURL = event.target['result'];
      }
    }
  }


}
