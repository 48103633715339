import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../core.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmployeeParameterService } from '../employee-parameter.service';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})
export class AddEmployeeComponent implements OnInit {

  addEmployeeForm: FormGroup;
  submitted = false;
  employeeData = [];
  public imagePath;
  imgURL: any;
  image: any;
  public message: string;
  departments= [];
  schools = [];
  subscribers: any [];

  sites: any = [];
  selectedSite: any = [];
  selectAllCheck = false;

  userRole = JSON.parse(localStorage.getItem('user_login_data')).role[0];
  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private employeeParameterService: EmployeeParameterService
  ) { }

  ngOnInit() {
    this.imgURL = './assets/images/empty_photo_frame.png';
    this.addEmployeeForm = this.formBuilder.group({
      name: ['', Validators.required],
      alias: '',
      sex: ['', Validators.required],
      email: ['', Validators.required],
      mobile: '',
      staff_id: '',
      department: ['', Validators.required],
      // nric: ['', Validators.required],
      nric: '',
      image: ['', Validators.required],
      subscriber_id: ['', Validators.required],
      school_id: '',
    });

    this.getDepartments();
    this.getSubscribers();
    this.getSchools();
    this.getSites();
  }
  get f() { return this.addEmployeeForm.controls; }

  getDepartments() {
    let url = 'api/department';

    if(this.subscriber_id){
      url += '?subscriber_id='+this.subscriber_id;
    }

    this.coreService.getRequest(url)
    .then((data) => {
      if (data && data.success) {
        // this.departments = data.data.departments.data;
        let departmentdata = [];
        for (var i = 0; i < data.data.departments.data.length; i++){
          if (data.data.departments.data[i].status == 'active') {
            console.log(this.userRole.name);
            console.log(((this.userRole.name).toLowerCase()).indexOf('school'));
            console.log(data.data.departments.data[i].name);
            if(((this.userRole.name).toLowerCase()).indexOf('school') !== -1 && data.data.departments.data[i].name != 'Associate Lecturer') {
              continue;
            }
            departmentdata.push(data.data.departments.data[i]);
          }
        }
        this.departments = departmentdata;
      }
    },
    (error) => {
      console.log(error);
      let data = JSON.parse(error._body);
      alert("error: " + data.message);
    });
  }

  getSites() {
    this.coreService.getRequest('api/facial_thermal_device_group?subscriber_id='+this.subscriber_id)
    .then((data) => {
      if (data && data.success) {
        this.sites = data.data.facial_thermal_device_groups.data;
      }
    },(error) => {
        let data = JSON.parse(error._body);
        console.log("error: " + data.errors[0]);
    });
  }

  getSubscribers() {
    this.coreService.getRequest('api/subscriber')
    .then((data) => {
      if (data && data.success) {
        this.subscribers = data.data.subscribers.data;
        if(this.subscriber_id){
          for (var i = 0; i < data.data.subscribers.data.length; i++){
            if(this.subscriber_id == data.data.subscribers.data[i].id){
              this.subscribers[i].selected = true;
              this.addEmployeeForm.patchValue({
                subscriber_id: this.subscribers[i].id
              });
              break;
            }
          }
        }
      }
    },(error) => {
      let data = JSON.parse(error._body);
      console.log("error: " + data.message);
    });
  }

  getSchools() {
    let url = 'api/school';

    this.coreService.getRequest(url)
    .then((data) => {
      if (data && data.success) {
        for (var i = 0; i < data.data.schools.data.length; i++){
          this.schools = data.data.schools.data;
        }
      }
    },
    (error) => {
      console.log(error);
      let data = JSON.parse(error._body);
      alert("error: " + data.message);
    });
  }

  onSelectAll(type) {
    let groupSite = [];
    if(this.selectAllCheck){
      for (var i = 0; i < this.sites.length; i++) {
        groupSite.push(this.sites[i].id);
      }
    }
    this.selectedSite = groupSite;
  }

  onSubmit() {
    this.submitted = true;
    let postParams = this.addEmployeeForm.value;
    postParams.ftdg_ids = JSON.stringify(this.selectedSite);
    // stop here if form is invalid
    if (this.addEmployeeForm.valid) {
      postParams.image = this.imgURL
      console.log(postParams);
      this.coreService.postRequest('api/staff', postParams)
      .then((data) => {
        alert('Successfully Added Employee');
        this.employeeParameterService.resetParameterService();
        this.router.navigate(['/employee/list']);
      },
      (error) => {
        console.log(error);
        let data = JSON.parse(error._body);
        alert("error: " + data.errors[0]);
      })
    }
  }

  onReset() {
    this.submitted = false;
    this.addEmployeeForm.reset();
  }

  preview(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
          this.imgURL = event.target['result'];
      }
    }
  }

}
