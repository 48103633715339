import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { HttpParams } from '@angular/common/http';
// import {Md5} from 'ts-md5/dist/md5';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CoreService {
	api_url = environment.apiEndpoint;

  	constructor(
		public http: Http
	) {
	}

  	putRequest(api_call, data): Promise<any>{
		var headers = new Headers();
		headers.append("Content-Type", 'application/json');

		var user_data = JSON.parse(localStorage.getItem('user_login_data'));
		if (user_data && user_data.token) {
			headers.append("Authorization", 'Bearer '+user_data.token);
		}

		let options = new RequestOptions({headers: headers});

		return this.http.put(this.api_url+api_call, data, options)
		.toPromise()
		.then(response => response.json() as any, this.handleError);
  	}

  	postRequest(api_call:string , data: any): Promise<any>{
		var headers = new Headers();

		let nonJsonTypeApi = [
			'student_batch',
			'employee_batch',
			'event/batch_send_email',
			'visit_request_batch'
		];

		nonJsonTypeApi = nonJsonTypeApi.map(e => 'api/' + e);
		
		if(!nonJsonTypeApi.includes(api_call)) {
			headers.append("Content-Type", 'application/json');
		}

		var user_data = JSON.parse(localStorage.getItem('user_login_data'));
		if (user_data && user_data.token) {
			headers.append("Authorization", 'Bearer '+user_data.token);
			// console.log(user_data.token)
		}

		let options = new RequestOptions({headers: headers});

		return this.http.post(this.api_url+api_call, data, options)
		.toPromise()
		.then(response => response.json() as any, this.handleError);
	}

	async getRequest(api_call): Promise<any>{
		var headers = new Headers();
		// headers.append("Accept", 'application/json');
		headers.append("Content-Type", 'application/json');

		var user_data = JSON.parse(localStorage.getItem('user_login_data'));
		if (user_data && user_data.token) {
			headers.append("Authorization", 'Bearer '+user_data.token);
		}

		let options = new RequestOptions({headers: headers});

		// console.log(this.api_url+api_call);
		return this.http.get(this.api_url+api_call, options)
		.toPromise()
		.then(response => response.json() as any, this.handleError);
	}

	deleteRequest(api_call): Promise<any>{
		var headers = new Headers();
		headers.append("Content-Type", 'application/json');

		var user_data = JSON.parse(localStorage.getItem('user_login_data'));
		if (user_data && user_data.token) {
			headers.append("Authorization", 'Bearer '+user_data.token);
		}

		let options = new RequestOptions({headers: headers});

		// console.log(this.api_url+api_call);
		return this.http.delete(this.api_url+api_call, options)
		.toPromise()
		.then(response => response.json() as any, this.handleError);
	}

	downloadRequest(api_call: string): Promise<any> {
	  var headers = new Headers();
	  headers.append("Content-Type", 'application/json');
	//   headers.append("Content-Type", 'application/x-www-form-urlencoded');
	//   headers.append("Content-Type", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

	  var user_data = JSON.parse(localStorage.getItem('user_login_data'));
	  if (user_data && user_data.token) {
			headers.append("Authorization", 'Bearer '+user_data.token);
		}

		let options = new RequestOptions({
			headers: headers,
			responseType: ResponseContentType.Blob
		});	
		
	  return this.http.get(this.api_url+api_call, options)
		.toPromise()
		.then(response => response, this.handleError);
	}

	private handleError(error: any): Promise<any> {
		console.error('An error occurred.!!!!!!', error); // for demo purposes only
		console.log(error.status);
		if (error.status == 401) {
			localStorage.removeItem('user_login_data');
		}
		return Promise.reject(error.message || error);
	}
}
