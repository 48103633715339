import { ChangeDetectionStrategy, Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CoreService } from '../../core.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import DownloadHelper from '../../download-helper';

@Component({
  selector: 'app-student-attendance-report',
  templateUrl: './student-attendance-report.component.html',
  styleUrls: ['./student-attendance-report.component.css']
})
export class StudentAttendanceReportComponent implements OnInit {
  downloadReportForm: FormGroup;
  date = moment().format('YYYY-MM-DD');
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  subscriber_id = localStorage.getItem('curSubscriber');
  site_id = localStorage.getItem('curSite');

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private coreService: CoreService,
    private sanitizer: DomSanitizer,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
  }

  ngOnInit() {
    this.downloadReportForm = this.formBuilder.group({
      start_date: [this.formatter.format(this.fromDate), Validators.required],
      end_date: [this.formatter.format(this.toDate), Validators.required]
    });
    // this.downloadReportForm = this.formBuilder.group({
    //   date: [this.date, Validators.required]
    // });

  }

  onDownload() {
    let params = new HttpParams();
    // params = params.set('date', this.parseDateToString(this.downloadReportForm.value.date));
    params = params.set('start_date', this.downloadReportForm.value.start_date);
    params = params.set('end_date', this.downloadReportForm.value.end_date);

    this.coreService.downloadRequest('api/report/student_attendance?' + params.toString())
    .then(data => {
        // let options = { type: 'text/xlsx;charset=utf-8;' };
        // application/vnd.ms-excel
        let options = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
        var contentDisposition = data.headers.get('Content-Disposition');
        var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        DownloadHelper.createAndDownloadBlobFile(data._body, options, filename);
    })
    .catch(error => {
        let data = JSON.parse(error._body);
        alert("error: " + data.message);
    });
  }

  parseDateToString(dateObj: moment.MomentInput) {
    return moment(dateObj).format('YYYY-MM-DD');
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.downloadReportForm.patchValue({start_date: this.formatter.format(this.fromDate)});
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || this.fromDate == date)) {
      this.toDate = date;
      this.downloadReportForm.patchValue({end_date: this.formatter.format(this.toDate)});
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.downloadReportForm.patchValue({start_date: this.formatter.format(this.fromDate)});
      this.downloadReportForm.patchValue({end_date: this.formatter.format(this.toDate)});
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string, key: string, updateKey: string) {
    const parsed = this.formatter.parse(input);
    let patchValue = {};
    if(
      (parsed && this.calendar.isValid(NgbDate.from(parsed)))
      &&
      (
        (key === 'fromDate' && moment(this.formatter.format(NgbDate.from(parsed))).valueOf() <= moment(this.formatter.format(this.toDate)).valueOf())
        || (key === 'toDate' && moment(this.formatter.format(NgbDate.from(parsed))).valueOf() >= moment(this.formatter.format(this.fromDate)).valueOf())
      )
      ) {
      this[key] = NgbDate.from(parsed);
    } else {
      this[key] = currentValue;
    }
    patchValue[updateKey] = this.formatter.format(this[key]);
    this.downloadReportForm.patchValue(patchValue);
  }
}
